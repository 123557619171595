import { combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import { makeActionRequestEffect } from 'effects'
import { getAppConfig } from 'app-config'
import join from 'url-join'
import * as fromModuleTypes from './actions/types'

export const createEffects = (moduleActions) => {

  const appConfig = getAppConfig()

  const createEffect = makeActionRequestEffect({
    type: fromModuleTypes.CREATE,
    url: join(appConfig.app.api, 'vehiclerequest'),
    verb: 'post',
    onSuccess: moduleActions.createFulfilled,
    onError: moduleActions.createError,
  })


  const fetchEffect = makeActionRequestEffect({
    type: fromModuleTypes.FETCH,
      url: join(appConfig.app.api, 'vehiclerequest'),
    onSuccess: moduleActions.fetchFulfilled,
    onError: moduleActions.fetchError,
  })

  const onFetchedEffect = (action$, state$) =>
    action$.pipe(
      ofType(fromModuleTypes.FETCH_FULFILLED),
      map(({ payload }) => {
        const FleetId = payload.thirdPartyNumber
        const options = {
          resourceId: payload.agreementNo,
          queryParams: { FleetId }
        }
        return moduleActions.fetchCorrespondingContract({}, options)
      })
    )

  const fetchCorrespondingContractEffect = makeActionRequestEffect({
    type: fromModuleTypes.FETCH_CORRESPONDING_CONTRACT,
    url: join(appConfig.app.api, 'contract/contract'),
    onSuccess: moduleActions.fetchCorrespondingContractFulfilled,
    onError: moduleActions.fetchCorrespondingContractError,
  })

  const updateEffect = makeActionRequestEffect({
    type: fromModuleTypes.UPDATE,
    url: join(appConfig.app.api, 'vehiclerequest'),
    verb: 'put',
    onSuccess: moduleActions.updateFulfilled,
    onError: moduleActions.updateError,
  })

  // const updateLeaseInformationEffect = makeActionRequestEffect({
  //   type: fromModuleTypes.UPDATE_LEASE_INFORMATION,
  //   url: join(appConfig.app.api, 'vehiclerequest'),
  //   verb: 'put',
  //   onSuccess: moduleActions.updateLeaseInformationFulfilled,
  //   onError: moduleActions.updateLeaseInformationError,
  // })

  // const updateVehicleInformationEffect = makeActionRequestEffect({
  //   type: fromModuleTypes.UPDATE_VEHICLE_INFORMATION,
  //   url: join(appConfig.app.api, 'vehiclerequest'),
  //   verb: 'put',
  //   onSuccess: moduleActions.updateVehicleInformationFulfilled,
  //   onError: moduleActions.updateVehicleInformationError,
  // })

  // const updateAdditionalServicesEffect = makeActionRequestEffect({
  //   type: fromModuleTypes.UPDATE_ADDITIONAL_SERVICES,
  //   url: join(appConfig.app.api, 'vehiclerequest'), 
  //   verb: 'put',
  //   onSuccess: moduleActions.updateAdditionalServicesFulfilled,
  //   onError: moduleActions.updateAdditionalServicesError,
  // })

  // const updateAccessoriesEffect = makeActionRequestEffect({
  //   type: fromModuleTypes.UPDATE_ACCESSORIES,
  //   url: join(appConfig.app.api, 'vehiclerequest'), 
  //   verb: 'put',
  //   onSuccess: moduleActions.updateAccessoriesFulfilled,
  //   onError: moduleActions.updateAccessoriesError,
  // })

  const fetchContractEffect = makeActionRequestEffect({
    type: fromModuleTypes.FETCH_CONTRACT,
    url: join(appConfig.app.api, 'contract/contract'),
    onSuccess: moduleActions.fetchContractFulfilled,
    onError: moduleActions.fetchContractError,
  })

  const deleteEffect = makeActionRequestEffect({
    type: fromModuleTypes.DELETE,
    url: join(appConfig.app.api, 'vehiclerequest'),
    verb: 'delete',
    onSuccess: moduleActions.removeFulfilled,
    onError: moduleActions.removeError,
  })

  const refetchEffect = (action$, state$) =>
    action$.pipe(
      ofType(
        // fromModuleTypes.UPDATE_FULFILLED_LEASE_INFORMATION,
        // fromModuleTypes.UPDATE_FULFILLED_VEHICLE_INFORMATION,
        // fromModuleTypes.UPDATE_FULFILLED_ADDITIONAL_SERVICES,
        // fromModuleTypes.UPDATE_FULFILLED_ACCESSORIES,
        fromModuleTypes.CREATE_FULFILLED,
        fromModuleTypes.UPDATE_FULFILLED,
        fromModuleTypes.DELETE_FULFILLED
      ),
      mergeMap(() => of(
        moduleActions.refetch(),
      ))
    )

  const lookupEffect = makeActionRequestEffect({
    type: fromModuleTypes.LOOKUP,
    url: join(appConfig.app.api, `lookup`),
    onSuccess: moduleActions.lookupFulfilled,
    onError: moduleActions.lookupError,
  })

  return combineEpics(
    // updateLeaseInformationEffect,
    // updateVehicleInformationEffect,
    // updateAdditionalServicesEffect,
    // updateAccessoriesEffect,
    fetchEffect,
    fetchCorrespondingContractEffect,
    fetchContractEffect,
    refetchEffect,
    onFetchedEffect,
    deleteEffect,
    lookupEffect,
    createEffect,
    updateEffect,
  )
}