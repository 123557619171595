import React, { createContext, useMemo, useState } from 'react'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { Card, CardContent } from '@material-ui/core'
import PropTypes from 'prop-types'
/**
 * Form View
 */
import LeaseInformation from './lease-information'
import VehicleInformation from './vehicle-information'
import AdditionalServices from './additional-services'
import Accessories from './accessories'
import Details from '../item/details'

export const FormContext = createContext({})

const Form = ({
  onSubmit,
  userRoles,
  activeTab,
  data,
  error,
  errorMessage
}) => {
  const [values, setValues] = useState({
  })

  const [searchName, setSearchName] = useState('');
  const [results, setResults] = useState(null);
  const [selectedDriver, setSelectedDriver] = useState(null);

  const onInputChange = ({ target: { id, name, value } }) => {
    setValues({ ...values, [id || name]: value })
  }

  const onSelectChange = (id) => (value) => {
    setValues({ ...values, [id]: value })
  }

  const onSwitchChange = (id) => (event) => {
    setValues((values) => ({
      ...values,
      [id]: !Boolean(values[id])
    }));
  };

  const memoizedValues = useMemo(() => {
    return {
      values,
      results,
      searchName,
      selectedDriver,
      data,
      onSelectChange,
      onInputChange,
      onSwitchChange,
      setSearchName,
      setResults,
      setSelectedDriver,
    }
  }, [
    values,
    results,
    searchName,
    selectedDriver,
    data,
  ])

  return (
    <FormContext.Provider value={{ ...memoizedValues, setValues }}>
      <Card
        sx={{
          boxShadow: 'none',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomRightRadius: '5px',
          borderBottomLeftRadius: '5px',
        }}
      >
        <CardContent
          sx={{
            p: 3,
          }}
        >
          <>
            {data?.vehicleOrderDetails?.status === "Submitted" ? (
              <Details data={data} />
            ) : (
              <>
                {activeTab === "lease_information" && (
                  <LeaseInformation userRoles={userRoles} data={data} onSubmit={onSubmit} />
                )}
                {activeTab === "vehicle_information" && (
                  <VehicleInformation data={data} onSubmit={onSubmit} />
                )}
                {activeTab === "additional_services" && (
                  <AdditionalServices data={data} onSubmit={onSubmit} />
                )}
                {activeTab === "accessories" && (
                  <Accessories data={data} onSubmit={onSubmit} />
                )}
              </>
            )}
          </>
          {/* {data?.vehicleOrderDetails?.status == "Submitted" &&
            <Details
              data={data}
            />
          }
          {activeTab === "lease_information" &&
            <LeaseInformation
              userRoles={userRoles}
              data={data}
              onSubmit={onSubmit}
            />
          }
          {activeTab === "vehicle_information" && 
            <VehicleInformation
              data={data}
              onSubmit={onSubmit}
            />
          }
          {activeTab === "additional_services" &&
            <AdditionalServices
              data={data}
              onSubmit={onSubmit}
            />
          }
          {activeTab === "accessories" &&
            <Accessories 
              data={data}
              onSubmit={onSubmit}
            />
          } */}
        </CardContent>
      </Card>
    </FormContext.Provider>
  )
}

Form.propTypes = {
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeTab: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
}

export default Form