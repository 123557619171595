import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { Label, FleetIcon } from 'frame/components'
import { getDateColor, obfuscateAgreementNumber } from 'listable'
import { types } from '../../module'
import { createRowSelector, useRouter } from 'utils'
import { useEnabledFeatureToggleList } from 'features/settings'
import ErrorIcon from '@material-ui/icons/Error'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'

import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'

import TableCellView from 'frame/components/table-cell/table-cell'
import { themeV5 } from 'theme/v5'

const useStyles = makeStyles(theme => ({
  unReliableLabel: {
    ...theme.typography.bodySmall,
    color: theme.palette.brand,
  },
  unReliableLabelIcon: {
    height: 18,
    marginBottom: -4,
    position: 'absolute',
    marginLeft: '-24px',
    marginTop: '-1px',
  },
  unReliableTooltip: {
    backgroundColor: '#FFF',
    color: theme.palette.brand,
    filter: 'drop-shadow(0px 1.5px 1.5px gray)',
  },
  unReliableTooltipArrow: {
    color: '#FFF',
    top: '21px',
  }
}))

const Row = ({ item, columns, onView }) => {
  const classes = useStyles()
  const { history, location } = useRouter()
  const onSafeRowClick = createRowSelector(onView)

  const featureToggleList = useEnabledFeatureToggleList()
  const documentsReportFeatureToggle = featureToggleList.includes('DocumentsViewing')
  const tfp1839FeatureToggle = featureToggleList.includes('TFP1839')
  const kmExceptionsFeatureToggle = featureToggleList.includes('KMExceptions')
  const tfp1944FeatureToggle = featureToggleList.includes('TFP1944')
  const tfp2005FeatureToggle = featureToggleList.includes('TFP2005')

  const onViewDocumentsReport = () => {
    history.push(`/reports/documents?registration=${item.registration}`, { label: 'Vehicle Servicing',  path: location.pathname })
  }

  return (
    <TableRow
      hover
      sx={{
        boxSizing: 'border-box',
        cursor: 'pointer',
        verticalAlign: 'middle',
        height: '30px',
        borderBottom: '5px solid white',
      }}
      onClick={onSafeRowClick}
    >
      <TableCell sx={{ bgcolor: 'white', width: '10px', p: 0 }} />
      {columns.accountName && (
        <TableCellView isFirst>
          <FleetIcon fleetName={item.accountName} />
        </TableCellView>
      )}
      {columns.agreementNo && (
        <TableCellView>
          {obfuscateAgreementNumber(item.agreementNo || '-', true)}
        </TableCellView>
      )}
      {columns.registration && (
        <TableCellView>
          {item.registration || '-'}
        </TableCellView>
      )}
      {columns.driverNote && tfp1944FeatureToggle && (
        <TableCellView>
          {item.driverNote || '-'}
        </TableCellView>
      )}
      {columns.driver && (
        <TableCellView>
          {item.driver || '-'}
        </TableCellView>
      )}
      {columns.year && (
        <TableCellView>
          {item.year || '-'}
        </TableCellView>
      )}
      {columns.make && (
        <TableCellView>
          {item.make || '-'}
        </TableCellView>
      )}
      {columns.model && (
        <TableCellView>
          {item.model || '-'}
        </TableCellView>
      )}
      {columns.variant && (
        <TableCellView>
          {item.variant || '-'}
        </TableCellView>
      )}
      {columns.fuelType && (
        <TableCellView>
          {item.fuelType || '-'}
        </TableCellView>
      )}
      {columns.costCentre && (
        <TableCellView>
          {item.costCentre || '-'}
        </TableCellView>
      )}
      {columns.controller && tfp1839FeatureToggle && (
        <TableCellView>
          {item.controller || '-'}
        </TableCellView>
      )}      
      {columns.startDate && (
        <TableCellView>
          {item.startDate ? moment(item.startDate).format('DD/MM/YY') : '-'}
        </TableCellView>
      )}
      {columns.dateOfLastService && (
        <TableCellView>
          {item.dateOfLastService ? moment(item.dateOfLastService).format('DD/MM/YY') : '-'}
        </TableCellView>
      )}
      {columns.serviceOdometer && (
        <TableCellView className={item.isReliable===false && kmExceptionsFeatureToggle ? classes.unReliableLabel : ""}>
          {item.isReliable===false && kmExceptionsFeatureToggle &&
            <Tooltip title="NOT RELIABLE" arrow placement="top" classes={{ tooltip: classes.unReliableTooltip, arrow: classes.unReliableTooltipArrow }}>
              <ErrorIcon className={classes.unReliableLabelIcon} />
            </Tooltip>
          }
          {item.serviceOdometer ? parseInt(item.serviceOdometer, 10).toLocaleString('en-NZ') : '-'}
        </TableCellView>
      )}
      {columns.dateOfLastKmReading && (
        <TableCellView>
          {item.dateOfLastKmReading ? moment(item.dateOfLastKmReading).format('DD/MM/YY') : '-'}
        </TableCellView>
      )}
      {columns.sourceOfKmReading && (
        <TableCellView>
          {item.sourceOfKmReading || '-'}
        </TableCellView>
      )}
      {columns.odometer && (
        <TableCellView>
          {item.odometer ? parseInt(item.odometer, 10).toLocaleString('en-NZ') : '-'}
        </TableCellView>
      )}
      {columns.estNextServiceDueDate && (
        <TableCellView>
          {item.estNextServiceDueDate ? (
            <Label
              color={getDateColor(item.estNextServiceDueDate)}
              variant="outlined">
              {moment(item.estNextServiceDueDate).format('DD/MM/YY')}
            </Label>
          ) : '-'}
        </TableCellView>
      )}
      {columns.estServiceDateLogic && (
        <TableCellView>
          {item.estServiceDateLogic || '-'}
        </TableCellView>
      )}
    
      {tfp2005FeatureToggle && columns.maturityDate && (
        <TableCellView>
          {item.maturityDate ? (
            <Label
              color={getDateColor(item.maturityDate)}
              variant="outlined">
              {moment(item.maturityDate).format('DD/MM/YY')}
            </Label>
          ) : '-'}
        </TableCellView>
      )}
      {tfp2005FeatureToggle && columns.originalMaturityDate && (
        <TableCellView>
          {item.originalMaturityDate ? moment(item.originalMaturityDate).format('DD/MM/YY') : '-'}
        </TableCellView>
      )}
      {columns.agreementType && (
        <TableCellView>
          {item.agreementType || '-'}
        </TableCellView>
      )}
      {columns.serviceBooked && (
        <TableCellView>
          {item.serviceBooked ? 'Yes' : 'No'}
        </TableCellView>
      )}
      {columns.serviceStatus && (
        <TableCellView>
          {item.serviceStatus || '-'}
        </TableCellView>
      )}
      {columns.bookedDate && (
        <TableCellView>
          {item.bookedDate ? (
            <Label
              variant="outlined">
              {moment(item.bookedDate).format('DD/MM/YY')}
            </Label>
          ) : '-'}
        </TableCellView>
      )}
      {columns.bookedLocation && (
        <TableCellView>
          {item.bookedLocation || '-'}
        </TableCellView>
      )}
      {columns.completedLocation && (
        <TableCellView>
          {item.completedLocation || '-'}
        </TableCellView>
      )}
      
      {columns.documentsCount && documentsReportFeatureToggle && (
        <TableCellView>
          {( item.documentsCount > 0 ? (            
            <Tooltip title="View documents">
              <IconButton
                onClick={(event) => {
                  event.stopPropagation(); // Prevents the TableRow onClick from being triggered
                  onViewDocumentsReport();
                }}
              >
                <InsertDriveFileOutlinedIcon sx={{ color: themeV5.palette.primary.main }} />
              </IconButton>
            </Tooltip>
          ) : '-')}
        </TableCellView>
      )}
      {Object.values(columns).some(value => value === true) &&            
        (<TableCellView isLast></TableCellView>)
      }
    </TableRow>
  )
}

Row.propTypes = {
  item: types.ModelType.isRequired,
  columns: types.ColumnsType.isRequired,
  onView: PropTypes.func.isRequired,
}

export default Row
