import React, { useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { addServiceBooking, cancelServiceBooking, reset } from "features/report-vehicle-servicing/actions"
import moment from "moment"
import { useProfile } from "features/account/hooks"

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import { useTheme } from "@mui/material/styles"

import { useForm, Controller, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import MuiTextfield from "frame/components/mui-textfield/mui-textfield"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import { schema } from "./add-booking-details-form-schema"
import DatePicker from "frame/components/date-picker-v5"
import StyledModalV2, { StyledModalV2Actions } from "frame/components/styled-modal-v2"

const AddBookingDetailsForm = ({
  flags,
  readOnly,
  fleetData,
  serviceBookingData,
  apiResponse,
  // func
  onAddServiceBooking,
  onCancelServiceBooking,
  onReset,
  onBack,
  
}) => {

  const profile = useProfile()
  const theme = useTheme()

  const methods = useForm({
    resolver: zodResolver(schema),
    reValidateMode: "all",
  })

  const [values, setValues] = useState({
    bookedDate: moment(),
    bookedLocation: ""
  })
  
  const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false)

  const onInputChange = ({ target: { id, name, value } }) => {
    setValues({ ...values, [id || name]: value })
    methods.setValue(name, value, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }
  
  const onSelectChange = (id) => (value) =>
    setValues({ ...values, [id]: value })

  const onSubmit = () => {
    const payload = {
      driverName: profile.nickname,
      agreementNumber: fleetData.agreementNo,
      vehicleRegistration: fleetData.registration,
      ...values
    }
    console.log("[onAddServiceBooking] payload: ", payload)
    onAddServiceBooking(payload)
  }

  const onCancel = () => onCancelServiceBooking({ agreementNumber: fleetData?.agreementNo })

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Card
          sx={{
            boxShadow: "none",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: "5px",
            borderBottomLeftRadius: "5px",
          }}
        >
          <FormProvider>
            <form
              id="service-record-form"
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              <CardContent
                sx={{
                  p: 3
                }}
              >
                <Stack gap={2}>
                  <Stack>
                    <Typography variant="h5" fontWeight={600}>
                      Service Bookings
                    </Typography>
                    <Typography variant="bodyMedium" fontWeight={300}>
                      Add booking details or confirm a completed booking
                    </Typography>
                  </Stack>
                  <Stack gap={2} width={{ xs: "100%", md: "50%" }}>
                    <Stack>
                      <DatePicker
                        variant="filled"
                        id="bookedDate"
                        name="bookedDate"
                        label="Booked Date*"
                        format="DD/MM/YYYY"
                        minDate={dayjs()}
                        value={typeof values.bookedDate === "undefined" ? null : dayjs(values?.bookedDate)}
                        onChange={onSelectChange("bookedDate")}
                      />
                    </Stack>
                    <Stack gap={1}>
                    <Controller
                      name="bookedLocation"
                      control={methods.control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <MuiTextfield
                          {...field}
                          id="bookedLocation"
                          error={Boolean(methods.formState.errors?.bookedLocation?.message)}
                          label="Booked Dealership*"
                          value={values.bookedLocation || ""}
                          onChange={onInputChange}
                        />
                      )}
                    />
                    <Typography color="error" fontSize={12}>
                      {methods.formState.errors?.bookedLocation?.message}
                    </Typography>
                  </Stack>
                  </Stack>
                </Stack>

                <Divider sx={{ mt: 3, mb: 2, borderColor: theme.palette.accent.sky }} />

                <Stack
                  flexDirection={{ xs: "column", md: "row" }}
                  justifyContent="space-between"
                  gap={1}
                >
                  <Box width={{ xs: "100%", md: "205px" }}>
                    <Button
                      fullWidth
                      color="secondary"
                      variant="contained"
                      onClick={onBack}
                    >
                      Back
                    </Button>
                  </Box>
                  <Stack flexDirection="row" gap={2}>
                    <Box width={{ xs: "100%", md: "205px" }}>
                      <Button
                        fullWidth
                        type="submit"
                        color="primary"
                        variant="contained"
                      >
                        Save
                      </Button>
                    </Box>
                  </Stack>
                </Stack>
              </CardContent>
              </form>
          </FormProvider>
        </Card>
      </LocalizationProvider>

      <StyledModalV2
        open={showConfirmCancelModal}
        onClose={() => setShowConfirmCancelModal(false)}
        content={
          <Stack gap={2} justifyContent="center" alignItems="center">
            <Typography variant="h4" textAlign="center">
              By clicking on confirm you are indicating that the service booking you have recorded for this vehicle is not proceeding.
            </Typography>
            <Typography variant="h4" textAlign="center">
            NOTE: cancelling your service booking will only update the vehicle record within the Portal. You may need to contact the servicing dealership to confirm the cancellation.
            </Typography>
          </Stack>
        }
        actions={
          <StyledModalV2Actions
            onCancel={() => setShowConfirmCancelModal(false)}
            onConfirm={onCancel}
          />
        }
      />

      <StyledModalV2
        open={flags.addServiceBooking === "processed" || flags.addServiceBooking === "error"}
        onClose={() => onReset()}
        status={flags.addServiceBooking === "processed" ? "success" : "error"}
        content={
          <Typography variant="h4" textAlign="center">
            {apiResponse}
          </Typography>
        }
      />
    </>
  )
}

AddBookingDetailsForm.propTypes = {
  readOnly: PropTypes.bool,
  flags: PropTypes.object.isRequired,
  fleetData: PropTypes.object.isRequired,
  errorMessage: PropTypes.string.isRequired,
  apiResponse: PropTypes.string.isRequired,
  // func
  onAddServiceBooking: PropTypes.func.isRequired,
  onCancelServiceBooking: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onBack: PropTypes.func,
}

const mapStateToProps = ({ reportVehicleServicing }) => ({
  flags: reportVehicleServicing.flags,
  errorMessage: reportVehicleServicing.errorMessage || "",
  apiResponse: reportVehicleServicing.apiResponse || "",
})

const mapDispatchToProps = (dispatch) => ({
  onAddServiceBooking: (payload) => {
    dispatch(addServiceBooking(payload))
  },
  onCancelServiceBooking: (payload) => {
    dispatch(cancelServiceBooking(payload))
  },
  onReset: () => {
    dispatch(reset())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(AddBookingDetailsForm)