import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { Divider, Typography, Tooltip, IconButton, TextField } from '@material-ui/core'
import { Label, LookupField, TextClip, CustomDatePicker } from 'frame/components'
// import { DatePicker } from '@material-ui/pickers'
import { hasRoles } from 'acl'

import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import ClearIcon from '@material-ui/icons/Clear'


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    position: 'relative',
    padding: theme.spacing(1),
  },
  label: {
    paddingRight: theme.spacing(1),
    minWidth: '100px',
    fontWeight: 'bold',
    fontSize: '12px',
  },
  large: {
    minWidth: '150px',
  },
  options: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
  },
  tooltip: {
    marginLeft: theme.spacing(1),
  },
  icon: {
    width: 20,
    height: 20,
  },
  spacer: {
    display: 'flex',
    width: '80px',
    height: '20px',
  },
  editable: {
    flex: 1,
    position: 'relative',
  },
  editTextField: {
    flex: 1,
    position: 'absolute',
    top: '-5px',
    left:'-9px',
    margin: 0,
    padding: 0,
    width: '100%',
    border: 'none',
    '& > div': {
      margin: 0,
      padding: '6px 8px',
      border: '1px solid rgba(0, 0, 0, 0.15)',
    },
    '& fieldset': {
      margin: 0,
      padding: 0,
      border: 'none',
    },
    '& input': {
      margin: 0,
      padding: 0,
      border: 'none',
    },
  },
  editDateField: {
    flex: 1,
    position: 'absolute',
    top: '-5px',
    left:'-9px',
    margin: 0,
    padding: 0,
    '& > div': {
      margin: 0,
      padding: '6px 8px',
      borderRadius: '4px',
      border: '1px solid rgba(0, 0, 0, 0.15)',
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
    '& input': {
      margin: 0,
      padding: 0,
      border: 'none',
    },
  },
  lookupField: {
    display: 'flex',
    minWidth: '320px',
    borderRadius: 4,
    border: '1px solid #ced4da',
    marginTop: '-18px',
    [theme.breakpoints.down('md')]: {
      minWidth: '200px',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '100px',
    },
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
      '& > div': {
        background: theme.palette.background.paper,
      },
    },
    '& label': {
      background: theme.palette.background.paper,
    },
    '& .MuiInputBase-input': {
      margin: 0,
      padding: '1px 1px !important',
      background: 'none !important',
    },
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1.35, 2),
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(10px, 10px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(10px, -4px) scale(0.75)',
    },
  },
  dropdown: {
    position: 'relative',
    bottom: '-5px',
    left:'-8px',
  },
  list: {
    marginTop: '-5px',
    marginLeft: '-20px',
    [theme.breakpoints.up('md')]: {
      maxHeight: '400px',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
    },
  },
  listItem: {
    width: '450px',
  },
  errorText: {
    color: '#e53935',
    fontSize: '11px',
    marginTop: '3px',
    marginLeft: '15px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: '13px',
    letterSpacing: '0.33px',
    [theme.breakpoints.up('xs')]: {
      marginLeft: '165px',
    },
  },
}))

const ValueText = ({
  required,
  color,
  format,
  label,
  large,
  outline,
  value,
  onCancel,
  onSave,
  onInputChange,
  onDateChange,
  onLookupChange,
  lookupLabel,
  lookupPath,
  userRoles,
  disabled,
  numbersOnly,
  minDate,
  maxDate,
  // maxDateMessage,
  isList,
  clipText,
  disabledPastDates,
  toggleEdit, // to toggle edit mode from outside this valueText component
  onEdit, // custom function passed to call on editmode select
  lookupDefaultOptions,
  onLookup,
  resourceModel,
}) => {

  const classes = useStyles()
  const [inputValue, setInputValue] = useState(value || '') // to keep track of value change locally for required field validation
  const [editMode, setEditMode] = useState(false)
  const [showIsRequiredError, setShowIsRequiredError] = useState(false)

  useEffect(() => {
    if(toggleEdit) {
      setEditMode(false)
      setShowIsRequiredError(false)
    }
  }, [toggleEdit])

  useEffect(() => {    
    required && setShowIsRequiredError(inputValue === '')
    // eslint-disable-next-line
  }, [inputValue])

  const hasAccess = ! hasRoles(['fleetcoordinator'], userRoles)
  const editable = !disabled && hasAccess && (onLookupChange || onInputChange || onDateChange)
  const editableLookup = onLookupChange ? true : false
  const editableDate = onDateChange ? true : false

  const restyle = color
    ? { color }
    : {}

  if (format === 'number' && value && !editMode) {
    value = Number(value).toLocaleString('en-NZ')
  }
  else if (format === 'currency' && value) {
    value = Number(value).toLocaleString('en-NZ', {
      style: 'currency',
      currency: 'NZD',
    })
  }
  else if (format === 'percentage' && value) {
    value = Number(value).toLocaleString('en-NZ')
    value = `${value}%`
  }
  else if (format && value && ! editMode) {
    value = moment(value).format(format)
  }

  const onSaveHandler = () => {
    if (editMode) {
      if(required) {
        value !== '' && onSave && onSave(value)
      } else {
        onSave && onSave(value)
      }

    }
    setEditMode(! editMode)    
    onEdit && onEdit() // call custom onEdit() function if onEdit prop exists
  }

  const onClearHandler = () => {
    setShowIsRequiredError(false)
    onCancel()
    setEditMode(! editMode)
  }

  const list = isList && value && value.split(', ')
  const displayValueField = () => {
    if(outline) {
      return (
        <Label color={outline} variant="outlined">
          {value || '-'}
        </Label>
      )
    } else if (isList) {
      return (
        <Typography style={restyle} variant="body1">
          <ul className={classes.list}>
            {list && list.map(item =>
              <li className={classes.listItem}>
              {item.trim()}
              </li>
            )}
          </ul>
        </Typography>
      )
    } else {
      return (
        <Typography style={restyle} variant="body1">
          {clipText
            ? <TextClip text={value} numberOfCharacters={100} />
            : (value || '-')
          }
        </Typography>
      )
    }
  }

  const displayEditField = () => {
    if(editableLookup) {
      return (
        <ValidatorForm autoComplete="off" noValidate>
          <div className={classes.dropdown}>
            <LookupField
              className={classes.lookupField}
              id="lookup-valuetext"
              fullWidth
              label={lookupLabel || 'Start typing...'}
              lookupPath={lookupPath}
              value={value || ''}
              variant="outlined"
              onChange={onLookupChange}
              defaultOptions={lookupDefaultOptions || []}
              onLookup={onLookup}
              resourceModel={resourceModel}
            />
          </div>
        </ValidatorForm>
      )
    } else if (editableDate) {
      return (
        <div className={classes.editable}>
          <CustomDatePicker
            value={value}
            onChange={onDateChange}
            format="DD/MM/YYYY"
            disabledPastDates={disabledPastDates}
            minDate={minDate ? moment(minDate)  : null}
            maxDate={maxDate ? moment(maxDate) : null}
            textFieldClassName={classes.editDateField}
          />
          {/* <DatePicker
            className={classes.editDateField}
            // format="DD/MM/YYYY"
            fullWidth
            value={value}
            // disablePast={disabledPastDates}
            // minDate={minDate && moment(minDate)}
            // maxDate={maxDate && moment(maxDate)}
            // maxDateMessage={maxDateMessage && maxDateMessage}
            variant="outlined"
            onChange={onDateChange} /> */}
        </div>
      )
  } else {
      return (
        <div className={classes.editable}>
          <style scoped>
            {`
              .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
                border-color: ${required && showIsRequiredError ? '#e53935' : '#263238'};
              }
            `}
          </style>
          <TextField
            autoFocus
            className={classes.editTextField}
            size="small"
            variant="outlined"
            inputProps={{ maxLength: 50 }}
            onChange={e => {
              onInputChange(e)
              required && setInputValue(e.target.value)
            }}
            value={value}            
          />
        </div>
      )
    }
  }

  // check if value contains numbers only or is require field
  const disabledSave = (numbersOnly && value && value.match(/\D/) !== null) || (required && showIsRequiredError)

  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <Typography
          className={clsx(classes.label, large ? classes.large : '')}
          variant="body1">
          {label}
        </Typography>

        {editMode ? displayEditField() : displayValueField()}

        {editable && (
          <div className={classes.spacer} />
        )}

        <div className={classes.options}>
          {editable && (
            <Tooltip
              className={classes.tooltip}
              title={editMode ? 'Save' : 'Edit'}>
              <IconButton
                size="small"
                disabled={disabledSave}
                onClick={onSaveHandler}>
                {editMode ? (
                  <SaveIcon className={classes.icon} />
                ) : (
                  <EditIcon className={classes.icon} />
                )}
              </IconButton>
            </Tooltip>
          )}
          {editMode && (
            <Tooltip
              className={classes.tooltip}
              title="Cancel">
              <IconButton
                size="small"
                onClick={onClearHandler}>
                <ClearIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      {required && showIsRequiredError && (
        <p className={classes.errorText}>This field is required</p>
      )}
      <Divider />
    </div>
  )
}

ValueText.propTypes = {
  required: PropTypes.bool,
  color: PropTypes.string,
  format: PropTypes.string,
  label: PropTypes.string.isRequired,
  large: PropTypes.bool,
  outline: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  onCancel: PropTypes.func,
  onDateChange: PropTypes.func,
  onInputChange: PropTypes.func,
  onLookupChange: PropTypes.func,
  lookupLabel: PropTypes.string,
  lookupPath: PropTypes.string,
  onSave: PropTypes.func,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool,
  numbersOnly: PropTypes.bool,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  maxDateMessage: PropTypes.string,
  isList: PropTypes.bool,
  clipText: PropTypes.bool,
  disabledPastDates: PropTypes.bool,
  toggleEdit: PropTypes.bool,
  onEdit: PropTypes.func,
  lookupDefaultOptions: PropTypes.array,
  onLookup: PropTypes.func,
  resourceModel: PropTypes.func,
}

const mapStateToProps = ({ settings }) => ({
  fleetIds: settings.fleet.selected,
  userRoles: settings.roles,
})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ValueText)