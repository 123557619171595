import React, {useContext} from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import MuiSwitch from 'frame/components/switch'
import { FormContext } from '../form'
import PropTypes from 'prop-types'

export const AdditionalServicesForm = ({ name, label }) => {
  const formContext = useContext(FormContext);
  const { values, onInputChange, onSwitchChange } = formContext;

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    onInputChange({ target: { name, value: checked } });
  };

  return (
    <FormControlLabel
      control={
        <MuiSwitch
          name={name}
          checked={Boolean(values[name]) || false}
          onChange={onSwitchChange(name)}
        />
      }
      label={label}
    />
  );
};

AdditionalServicesForm.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onSwitchChange: PropTypes.func.isRequired,
}
