import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { ValidatorForm } from "react-material-ui-form-validator"
import { OfflineLookupField, StyledModal, Dialog } from "frame/components"
import { useNavigate } from "utils"
import { constants } from "../../module"
import moment from "moment"

import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Stack from "@mui/material/Stack"

import { themeV5 } from "theme/v5"
import StyledModalV2 from "frame/components/styled-modal-v2"

const Form = ({ values, filtered, setValues, onClose, onProceed, onLookup, onSave }) => {
  const navigate = useNavigate()

  const [showQuestion, setShowQuestion] = useState(false)
  const [existingSubmissionId, setExistingSubmissionId] = useState(null)
  const [showConfirmSubmitModal, setShowConfirmSubmitModal] = useState(false)

  const onDriverNameChange = (option) => {
    console.log("[onDriverNameChange]: ", option)
    setValues({ ...values, driverName: option.text, costCentre: option?.costCentre })
    setExistingSubmissionId(option?.existingSubmissionId)
  }

  const onInputChange = (key) => (value) =>
    setValues({ ...values, [key]: value?.target?.value || value?.value || value || null })

  const onAddFbtSubmission = () => { // for all days of the month
    const payload = {
      ...values,
      availableForPvtUseAllMonth: true,
      entries: [],
    }
    console.log("[onAddFbtSubmission]: ", payload)
    onSave(payload)
    onClose()
  }

  useEffect(() => {    
    setShowQuestion(!!values?.driverName)
  }, [values?.driverName]) // eslint-disable-line

  const onNavigateToEdit = navigate(constants.PATH_SINGLE, existingSubmissionId, `view-submissions`)

  return (
    <ValidatorForm autoComplete="off" noValidate onSubmit={() => {}}>
    <Dialog
      open autoSize
      size="md"
      aria-labelledby="Add a new driver record"
      title="Add a new driver record"
      onClose={onClose}
    >
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent
              sx={{
                minHeight: 100,
                padding: "3 1.2",
                "@media (min-width:600px)": {
                  padding: "3",
                },
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <OfflineLookupField
                    fullWidth showAllResult
                    size="small"
                    id="driverName"
                    label="Driver"
                    customFleetId={values?.thirdPartyNumber}
                    lookupPath="drivernamefbt"
                    value={values?.driverName || ""}
                    variant="outlined"
                    onChange={onDriverNameChange}
                    refetchOnChange={values?.thirdPartyNumber}
                    onLookup={onLookup(
                      filtered?.year ? filtered?.year : moment().format("YYYY"),
                      filtered?.month ? moment().month(filtered?.month).format("M") : moment().subtract(1, "months").format("M")
                    )}
                    resourceModel="fbtSubmissions"
                  />
                </Grid>
                <Grid item xs={6}>
                  <OfflineLookupField
                    fullWidth showAllResult allowFreeText
                    size="small"
                    id="costCentre"
                    label="Cost Centre"
                    customFleetId={values?.thirdPartyNumber}
                    lookupPath="drivercostcentre"
                    value={values?.costCentre || ""}
                    variant="outlined"
                    onChange={onInputChange("costCentre")}
                    defaultOptions={values?.costCentre ? [{ text: values.costCentre, value: values.costCentre }] : []}
                    onLookup={onLookup(null, null)}
                    resourceModel="fbtSubmissions"
                    refetchOnChange={values?.driverName}
                    resetDefaultValueOnChange={values?.driverName}
                  />
                </Grid>
              </Grid>

              {showQuestion && !existingSubmissionId && (
                <>
                  <Typography
                    variant="h6"
                    align="center"
                    sx={{
                      marginTop: 3,
                    }}
                  >
                    Was {values?.driverName}'s vehicle available for private use for
                    <br/><strong>all days of last month?</strong>
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      "@media (max-width:600px)": {
                        display: "block",
                      },
                    }}
                  >
                    <Button
                      color="error"
                      variant="outlined" 
                      onClick={onProceed}
                    >
                      No
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => setShowConfirmSubmitModal(true)}
                    >
                      Yes
                    </Button>
                  </Box>
                </>
              )}

              {!!existingSubmissionId && (
                <>
                  <Divider sx={{ mt: 3, mb: 2, borderColor: themeV5.palette.accent.sky }} />
                  
                  <Typography 
                    variant="h6" 
                    align="right"
                    sx={{
                      marginTop: 2,
                      marginBottom: 2,
                    }}
                  >
                    {values?.driverName} already has a submission
                  </Typography>
                  <Stack
                    flexDirection={{ xs: "column", sm: "row" }}
                    justifyContent="space-between"
                    gap={1}
                  >
                    <Box width={{ xs: "100%", sm: "205px" }}>
                      <Button
                        fullWidth
                        color="error"
                        variant="outlined"
                        onClick={onClose}
                      >
                        Close
                      </Button>
                    </Box>
                    <Box width={{ xs: "100%", sm: "205px" }}>
                      <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        onClick={onNavigateToEdit}
                        sx={{
                          "@media (max-width:600px)": {
                            display: "block",
                          },
                        }}
                      >
                        Edit Submission
                      </Button>
                    </Box>
                  </Stack>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Dialog>
    
      
      {/* for save confirmation */}
      <StyledModal
        open={showConfirmSubmitModal}
        title={`Confirm Submission`}
        onCancel={() => setShowConfirmSubmitModal(false)}
        onCancelLabel="Go Back"
        onConfirm={() => {
          setShowConfirmSubmitModal(false)
          onAddFbtSubmission()
        }}>
        {`Please confirm you have completed ${values?.driverName}'s submission and their vehicle was available for private use for all days of last month.`}
      </StyledModal>
      
    </ValidatorForm>
  )
}

Form.propTypes = {
  values: PropTypes.object,
  filtered: PropTypes.object,
  // func
  setValues: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
  onLookup: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default Form
