import * as z from "zod";

export const schema = z.object({
  requestType: z
    .string()
    .optional(),
  accountName1: z
    .string()
    .optional(),
  leaseType1: z
    .string()
    .optional(),
  term1: z
    .string()
    .optional(),
  odometer1: z
    .string()
    .optional(),
  numberOfTyres1: z
    .string()
    .optional(),
  accountName: z
    .string({ required_error: "Fleet is required" })
    .min(1, { message: "Fleet is required" }),
  leaseType: z
    .string({ required_error: "Lease Type is required" })
    .min(1, { message: "Lease Type is required" }),
  term: z
    .string({ required_error: "Term is required" })
    .min(1, { message: "Term is required" }),
  // odometer: z
  //   .string({ required_error: "Kms is required" })
  //   .min(1, { message: "Kms is required" }),
  odometer: z
    .string()
    .optional(),
  numberOfTyres: z
    .string()
    .optional(),
  driverName: z
    .string()
    .optional(),
  driverEmail: z
    .string()
    .optional(),
  notes: z
    .string()
    .optional(),
});
