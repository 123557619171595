import React from "react"
import { Redirect } from "react-router-dom"
import { Page, PageHeader } from "frame/components"
import { useRouter } from "utils"
import { constants } from "../module"
import { ChildRoutes } from "../routes"
import TabMenu from "../../../features/fleet/components/drill-down-modal/tab-menu"

import Stack from "@mui/material/Stack"

const Account = () => {
  const { match } = useRouter()
  const { tab } = match.params

  const tabs = [
    { value: "profile", label: "Profile" },
    { value: "security", label: "Security" }
  ]

  const tabsConfig = [
    {
      label: "Profile",
      path: "profile",
    }, {
      label: "Security",
      path: "security",
    }
  ]

  const currentTab = tabs.filter(node => node.value === tab)[0]

  if ( ! tab || ! currentTab) {
    return <Redirect to={constants.ROUTE_PROFILE} />
  }

  return (
    <Page
      title={"Account"}
      sx={{
        display: "flex",
        justifyContent: "center",
        px: { xs: 3, md: 0 },
      }}
    >
      <Stack
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "1280px"
        }}
      >
        <Stack
          sx={{
            padding: '16px 0 32px 0'
          }}
        >
          <PageHeader title={"Account"} variant="h1" />
        </Stack>
        <TabMenu config={tabsConfig} />
        <ChildRoutes/>
      </Stack>
    </Page>
  )
}

export default Account
