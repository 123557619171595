import { hasRoles } from 'acl'
import { ADMIN_PATH } from 'features/admin'
import { FEATURE_TOGGLE_PATH } from 'features/feature-toggle'

import { constants as fromAccount } from 'features/account'
import { constants as fromAuth } from 'features/auth'

import { constants as fromSummaryDashboard } from 'features/homepage'
import { constants as fromDashboard } from 'features/dashboard'
import { constants as fromDrivers } from 'features/drivers'
import { constants as fromFleet } from 'features/fleet'

// reports
import { constants as fromReportEndOfLease } from 'features/report-end-of-lease'
import { constants as fromReportIncidentManagement } from 'features/report-incident-management'
import { constants as fromReportExtensionRentalsResults } from 'features/report-extension-rentals'
import { constants as fromReportKmTracking } from 'features/report-km-tracking'
import { constants as fromReportUpcomingMaturities } from 'features/report-upcoming-maturities'
import { constants as fromReportVehicleServicing } from 'features/report-vehicle-servicing'
import { constants as fromReportVehiclesOnOrder } from 'features/report-vehicles-on-order'
import { constants as fromReportWofRego } from 'features/report-wof-rego'
import { constants as fromDocuments } from 'features/report-documents'

// fbt
// import { constants as fromFbtSubmissions } from 'features/fbt-submissions'
// import { constants as fromFbtSummary } from 'features/fbt-summary'

// actions
import { constants as fromRequestReliefVehicles } from 'features/request-relief-vehicles'
import { constants as fromRequestVehicleRelocations } from 'features/request-vehicle-relocations'
import { constants as fromRequestContractVariations } from 'features/request-contract-variations'
import { constants as fromRequestVehicleSummary } from 'features/request-vehicle-summary'

import { useSelectedPremiumFleets } from 'features/settings'
import { useLatestFbtSubmission, useLatestFbtSummary } from 'features/fbt/navigation-config'
import { useEnabledFeatureToggleList } from 'features/settings'
import { useIsWhiteListed } from 'utils'

export const useDrawerMenu = (userRoles) => {
  const selectedFbtFleets = useSelectedPremiumFleets()
  const featureToggleList = useEnabledFeatureToggleList()
  const fbtFeatureToggle = featureToggleList.includes('FBT')
  const documentsReportFeatureToggle = featureToggleList.includes('DocumentsViewing')
  const noFbtFleetSelected = selectedFbtFleets.length === 0

  const isWhiteListedUser = useIsWhiteListed()

  const latestFbtSubmission = useLatestFbtSubmission()
  const latestFbtSummary = useLatestFbtSummary()

  const showFeatureToggle = isWhiteListedUser

  const isValidFbtLink = (path) => path.includes('month') || path.includes('quarter')


  const menu = [
    {
      label: 'Home',
      path: fromSummaryDashboard.PATH_ALL,
      visible: true,
    },
    {
      label: 'Dashboard',
      path: fromDashboard.PATH_ALL,
      visible: true,
    },
    {
      label: 'Reports',
      path: fromReportUpcomingMaturities.PATH_ALL,
      visible: true,
      subMenu: [        
        {
          label: 'Maturities',
          path: fromReportUpcomingMaturities.PATH_ALL,
          visible: true,
        },
        {
          label: 'Vehicles on Order',
          path: fromReportVehiclesOnOrder.PATH_ALL,
          visible: true,
        },
        {
          label: 'Vehicle Servicing',
          path: fromReportVehicleServicing.PATH_ALL,
          visible: true,
        },
        {
          label: 'KM Tracking',
          path: fromReportKmTracking.PATH_ALL,
          visible: true,
        },
        {
          label: 'WoF Registration',
          path: fromReportWofRego.PATH_ALL,
          visible: true,
        },
        {
          label: 'Extension Rentals',
          path: fromReportExtensionRentalsResults.PATH_ALL,
          visible: true,
        },
        {
          label: 'End of Lease',
          path: fromReportEndOfLease.PATH_ALL,
          visible: true,
        },
        {
          label: 'Incident Management',
          path: fromReportIncidentManagement.PATH_ALL,
          visible: true,
        },
        {
          label: 'Documents',
          path: fromDocuments.PATH_ALL,
          visible: documentsReportFeatureToggle,
        },
      ]
    },
    {
      label: 'Fleet',
      path: fromFleet.PATH_ALL,
      visible: true,
    },
    {
      label: 'Drivers',
      path: fromDrivers.PATH_ALL,
      visible: true,
    },
    {
      label: 'FBT',
      path: latestFbtSubmission.link,
      visible: fbtFeatureToggle && noFbtFleetSelected, // show FBT link only when no fbt fleet is selected
    },
    {
      label: 'FBT Submissions',
      path: latestFbtSubmission.link,
      visible: fbtFeatureToggle && isValidFbtLink(latestFbtSubmission.link) && !noFbtFleetSelected,
    },
    {
      label: 'FBT Summary',
      path: latestFbtSummary.link,
      visible: fbtFeatureToggle && isValidFbtLink(latestFbtSummary.link) && !noFbtFleetSelected,
    },
    {
      label: 'Relief Vehicle Booking Summary',
      path: fromRequestReliefVehicles.PATH_ALL,
      visible: true,
    },
    {
      label: 'Vehicle Relocations Summary',
      path: fromRequestVehicleRelocations.PATH_ALL,
      visible: true,
    },
    {
      label: 'Contract Variations Summary',
      path: fromRequestContractVariations.PATH_ALL,
      visible: true,
    },
    {
      label: 'Vehicle Order Summary',
      path: fromRequestVehicleSummary.PATH_ALL,
      visible: true,
    },
    {
      label: 'Account',
      path: fromAccount.PATH_ALL,
      visible: true,
    },
    {
      label: 'Admin',
      path: ADMIN_PATH,
      visible: hasRoles(['admin', 'superadmin', 'fleetcontroller'], userRoles),
    },
    {
      label: 'Feature Toggle',
      path: FEATURE_TOGGLE_PATH,
      visible: showFeatureToggle,
    },
    {
      label: 'Logout',
      path: fromAuth.LOGOUT_PATH,
      visible: true,
    },
  ].filter(x => x.visible)

  return {
    menu,
  }
}