import * as z from "zod";

export const schema = z.object({
  mobile: z
    .string()
    .refine(value => /^\d{9,11}$/.test(value), {
      message: 'Invalid mobile number',
    })
    .optional()
    .or(z.literal('')),
  driverNotification: z
    .string()
    .optional(),
  costCentre: z
    .string()
    .optional(),
  controller: z
    .string()
    .optional(),
  vehicleNotes: z
    .string()
    .optional(),
});
