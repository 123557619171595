import React from "react";
import { useTheme } from "@mui/material/styles"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

const ContractDetails = ({ items }) => {
  const theme = useTheme()
  return (
    <Stack data-id="fleet-contract-modal-tab">
      <Stack
        borderRadius='5px'
        border='1px solid'
        borderColor={theme.palette.accent.sky}
        p={1}
        flexDirection='row'
        flexWrap='wrap'
        gap={1}
      >
        {items?.map((item, index) => (
          <Stack
            key={`contract-details-${index}`}
            flex={1}
            minWidth='calc(100%/3)'
          >
            <Typography variant="bodyLarge" color={theme.palette.accent.sky}>
              {item?.label}
            </Typography>
            <Typography variant="bodyLarge">
              {item?.value}
            </Typography>
          </Stack>
        ))}

      </Stack>
    </Stack>
  )
}

export default ContractDetails