import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Layout } from 'frame'
import { Page } from 'frame/components'
import { checkHaveAppUsers } from '../actions'

// section components
import MyFleetSummary from './my-fleet-summary'

import MyNotifications from './my-notfications'
import StatusUpdates from './status-updates'
import IndustryNews from './industry-news'

import Downloads from './downloads/downloads'
import FuelCardPromo from './fuel-card-promo'
import ContactInformation from './contact-information'

import Footer from './footer'
import PromoCard from './promo-card'

/**
 * V5
 */
import Grid from "@mui/material/Grid"

const SummaryDashboard = ({ haveAppUsers, onCheckHaveAppUsers}) => {
  const showPromoCard = !haveAppUsers 

  useEffect(() => {
    onCheckHaveAppUsers()
  }, [onCheckHaveAppUsers])

  return (
    <Layout backgroundImage="url('/static/images/backgrounds/bg1.png')">
      <Page
        title="My Fleet Summary Dashboard"
        sx={{ p: 3 }}
      >
        <Grid
          container
          spacing={3}
          sx={{
            position: 'relative',
            '& > *': {
              height: '100%'
            }
          }}
        >
          <Grid item xs={12} style={{ height: "auto" }}>
            <MyFleetSummary />          
          </Grid>

          {/* 2nd Row */}
          <Grid item xs={12} sm={12} md={6} lg={4} style={{ height: "auto" }}>
            {showPromoCard ? <PromoCard /> : <MyNotifications />}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} style={{ height: "auto" }}>
            <StatusUpdates />
          </Grid>              
          <Grid item xs={12} sm={12} md={12} lg={4} style={{ height: "auto" }}>
            <IndustryNews />
          </Grid>
          {/* End of 2nd Row */}
          
          {/* 3rd Row */}
          <Grid item xs={12} sm={12} md={6} lg={4} style={{ height: "auto" }}>
            <Downloads />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} style={{ height: "auto" }}>
            <FuelCardPromo />
          </Grid>              
          <Grid item xs={12} sm={12} md={12} lg={4} style={{ height: "auto" }}>
            <ContactInformation />
          </Grid>
          {/* End of 3rd Row */}
        </Grid>
      </Page>

      <Footer />
    </Layout>
  )
}

SummaryDashboard.propTypes = {
  haveAppUsers: PropTypes.bool,
  onCheckHaveAppUsers: PropTypes.func.isRequired,
}

const mapStateToProps = ({ homepage }) => ({
  haveAppUsers: homepage?.haveAppUsers,
})

const mapDispatchToProps = (dispatch) => ({
  onCheckHaveAppUsers: () => dispatch(checkHaveAppUsers()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SummaryDashboard)