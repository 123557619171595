import React, {useContext, useState, useEffect} from 'react'
import { Box, Button, Divider, Stack, Typography}  from '@mui/material'
import MuiTextfield from 'frame/components/mui-textfield'
import { useNavigate, useRouter } from 'utils'
import { constants } from '../../../module'
import StyledModalV2, { StyledModalV2Actions } from 'frame/components/styled-modal-v2'
import { useTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'

import { AdditionalServicesForm } from './additional-services-form'
import { FormContext } from '../form'
import { Alert } from 'frame/components'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { schema } from './form-schema'

const AdditionalServices = ({
  onSubmit,
  error,
  errorMessage,
})=> {
  const formContext = useContext(FormContext);
  const navigate = useNavigate()
  const { match } = useRouter()
  const theme = useTheme()
  const {
    transferValues,
    onInputChange,
    values,
    setValues,
    data,
  } = formContext

  const methods = useForm({
    resolver: zodResolver(schema),
    reValidateMode: "all",
  })

  const servicesMenu = [
    {
      title: "Fuel Card and Reporting",
      name: "hasFuelCardAndReporting"
    },
    {
      title: "Relief Vehicle (14 Days)*",
      name: "hasReliefVehicle"
    },
    {
      title: "RUC Management",
      name: "hasRucManagement"
    },
    {
      title: "Accident Management",
      name: "hasAccidentManagement"
    },
    {
      title: "Toll Management / Recharge",
      name: "hasTollManagement"
    },
    {
      title: "Infringement Management Recharge",
      name: "hasInfringementManagement"
    },
    {
      title: "FBT Reporting",
      name: "hasFbtReporting"
    },
    {
      title: "Telematics",
      name: "hasTelematics"
    },
    {
      title: "Fleet Portal",
      name: "hasFleetPortal"
    },
    {
      title: "Driver App",
      name: "hasDriverApp"
    },
    {
      title: "Roadside Assistance",
      name: "hasRoadsideAssistance"
    }
  ]
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmSaveDialogOpen, setConfirmSaveDialogOpen] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const isEdit = data?.vehicleOrderDetails?.status === "Draft" || data?.vehicleOrderDetails?.status === null

  const onClose = navigate(constants.PATH_ALL)

  const handleConfirmClose = () => {
    setConfirmDialogOpen(false);
    navigate(constants.PATH_ALL);
  };

  const onSubmitForm = () => {
    if(values.vehicleRegistration && typeof values.vehicleRegistration === 'object' && values.vehicleRegistration !== null ) {
      values.vehicleRegistration = values.vehicleRegistration?.value;
    }
    if(values.requestType && values.leaseType && values.accountName && values.term ){
      onSubmit(values)
      setConfirmDialogOpen(true);
    } else {
      setValidationMessage('Please fill up mandatory fields* in Lease Information tab.')
    }
  }

  const handleProceed = () => {
    navigate(`${match.url}${constants.PATH_ACCESSORIES}`)(0)
  }

  useEffect(() => {
    if (!data) {
      return
    }
    const vehicleOrderDetails = data?.vehicleOrderDetails;
    setValues((values) => ({
      ...values,
      // hasFuelCardAndReporting: vehicleOrderDetails?.hasFuelCardAndReporting,
      // hasReliefVehicle: vehicleOrderDetails?.hasReliefVehicle,
      // hasRucManagement: vehicleOrderDetails?.hasRucManagement,
      // hasAccidentManagement: vehicleOrderDetails?.hasAccidentManagement,
      // hasTollManagement: vehicleOrderDetails?.hasTollManagement,
      // hasInfringementManagement: vehicleOrderDetails?.hasInfringementManagement,
      // hasFbtReporting: vehicleOrderDetails?.hasFbtReporting,
      // hasTelematics: vehicleOrderDetails?.hasTelematics,
      // hasFleetPortal: vehicleOrderDetails?.hasFleetPortal,
      // hasDriverApp: vehicleOrderDetails?.hasDriverApp,
      // hasRoadsideAssistance: vehicleOrderDetails?.hasRoadsideAssistance,
      // additionalServiceNotes: vehicleOrderDetails?.additionalServiceNotes,
      hasFuelCardAndReporting: vehicleOrderDetails.hasFuelCardAndReporting && !values.hasFuelCardAndReporting ? vehicleOrderDetails.hasFuelCardAndReporting : values.hasFuelCardAndReporting,
      hasReliefVehicle: vehicleOrderDetails.hasReliefVehicle && !values.hasReliefVehicle ? vehicleOrderDetails.hasReliefVehicle : values.hasReliefVehicle,
      hasRucManagement: vehicleOrderDetails.hasRucManagement && !values.hasRucManagement ? vehicleOrderDetails.hasRucManagement : values.hasRucManagement,
      hasAccidentManagement: vehicleOrderDetails.hasAccidentManagement && !values.hasAccidentManagement ? vehicleOrderDetails.hasAccidentManagement : values.hasAccidentManagement,
      hasTollManagement: vehicleOrderDetails.hasTollManagement && !values.hasTollManagement ? vehicleOrderDetails.hasTollManagement : values.hasTollManagement,
      hasInfringementManagement: vehicleOrderDetails.hasInfringementManagement && !values.hasInfringementManagement ? vehicleOrderDetails.hasInfringementManagement : values.hasInfringementManagement,
      hasFbtReporting: vehicleOrderDetails.hasFbtReporting && !values.hasFbtReporting ? vehicleOrderDetails.hasFbtReporting : values.hasFbtReporting,
      hasTelematics: vehicleOrderDetails.hasTelematics && !values.hasTelematics ? vehicleOrderDetails.hasTelematics : values.hasTelematics,
      hasFleetPortal: vehicleOrderDetails.hasFleetPortal && !values.hasFleetPortal ? vehicleOrderDetails.hasFleetPortal : values.hasFleetPortal,
      hasDriverApp: vehicleOrderDetails.hasDriverApp && !values.hasDriverApp ? vehicleOrderDetails.hasDriverApp : values.hasDriverApp,
      hasRoadsideAssistance: vehicleOrderDetails.hasRoadsideAssistance && !values.hasRoadsideAssistance ? vehicleOrderDetails.hasRoadsideAssistance : values.hasRoadsideAssistance,
      additionalServiceNotes: vehicleOrderDetails.additionalServiceNotes && !values.additionalServiceNotes ? vehicleOrderDetails.additionalServiceNotes : values.additionalServiceNotes,

      id: vehicleOrderDetails?.id,
    }))
  }, [data])

  return (
    <>
      <form
        id="request-additional-services-form"
        onSubmit={methods.handleSubmit(onSubmitForm)}
      >
        <Stack>
          {validationMessage && (
            <Stack gap={1}>
              <Alert>
                {validationMessage || 'Something went wrong while trying to submit vehicle order request.' }
              </Alert>
            </Stack>
          )}
          <Stack flexDirection={{ xs: "column", md: "row" }} gap={2}>
            {/* {!isEdit && ( */}
              <Stack flex={1}>
                {servicesMenu.map((service) => (
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                    py={1}
                    borderBottom="1px solid"
                    borderColor={theme.palette.neutral.mid}
                  >
                    <Stack flex={1}>
                      <Typography variant="h6" maxWidth={190}>{service.title}</Typography>
                    </Stack>
                    <Stack flex={1}>
                      <AdditionalServicesForm
                        key={service.name}
                        name={service.name}
                        label=''
                      />
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            {/* )} */}
          </Stack>
          <Stack flexDirection={{ xs: "column", md: "row" }} gap={2} paddingTop={2}>
            {/* {!isEdit && ( */}
              <Stack width="100%">
                <Controller
                  name="additionalServiceNotes"
                  control={methods.control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <>
                      <MuiTextfield
                        {...field}
                        multiline
                        rows={4}
                        id="additionalServiceNotes"
                        label="Notes"
                        inputProps={{ maxLength: 2000 }}
                        value={values.additionalServiceNotes || ""}
                        onChange={onInputChange}
                      />
                    </>
                  )}
                />
              </Stack>
            {/* )} */}
          </Stack>
          <Divider sx={{ mt: 3, mb: 2, borderColor: theme.palette.accent.sky }} />
          
          <Stack
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent="space-between"
            gap={1}
          >
            <Box width={{ xs: "100%", md: "205px" }}>
              <Button
                fullWidth
                color="error"
                variant="outlined"
                onClick={() => setConfirmSaveDialogOpen(true)}
              >
                Close
              </Button>
            </Box>
            <Stack flexDirection="row" gap={2}>
              <Box width={{ xs: "100%", md: "205px" }}>
                <Button
                  fullWidth
                  type="submit"
                  color="primary"
                  variant="outlined"
                >
                  Save and Exit
                </Button>
              </Box>
              <Box width={{ xs: "100%", md: "205px" }}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={handleProceed}
                >
                  Proceed
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Stack>
        <StyledModalV2
          open={confirmDialogOpen}
          onClose={() => {
            setConfirmDialogOpen(false);
            onClose();
          }}
          content={
            <Typography variant="h4" textAlign="center">
              {data?.vehicleOrderDetails?.status && data?.vehicleOrderDetails?.status == 'Draft' ? 'Vehicle order request updated successfully.' : 'Vehicle order request created successfully.'}
            </Typography>
          }
        />
        <StyledModalV2
          open={confirmSaveDialogOpen}
          onClose={() => setConfirmSaveDialogOpen(false)}
          content={
            <Typography variant="h4" textAlign="center">
              Any changes will not be saved. Are you sure you want to continue?
            </Typography>
          }
          actions={
            <StyledModalV2Actions
              onCancel={() => setConfirmSaveDialogOpen(false)}
              onConfirm={() => {
                setConfirmSaveDialogOpen(false);
                onClose();
              }}
            />
          }
        />
      </form>
    </>
  )
}

AdditionalServices.propTypes = {
  values: PropTypes.object.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onSwitchChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
}

export default AdditionalServices