import React from 'react'
import { useTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Divider from '@mui/material/Divider'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import { DialogClose } from 'frame/components/dialog/dialog-close'

const LeaseTypeDetails = ({onCancel}) => {
  const theme = useTheme()
  const onClose = () => onCancel()
  return (
    <Stack data-id="vehicle-order-lease-information-lt-modal-tab" width="520px">
      <Stack flexDirection={{ xs: "column", md: "row" }} gap={2}>
        <Stack flex={1}>
          <DialogClose onClose={onClose} />
        </Stack>
        <Stack flex={1}>
          <Stack flex={1} direction="row" alignItems="center" spacing={1}>
            <Stack width="50%">
              <Typography variant="h6">
                Fully Maintained Operating Lease
              </Typography>
            </Stack>
            <Stack width="50%">
              <Typography variant="h6">
                Non-Maintained Operating Lease
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Divider sx={{ mt: 3, mb: 1, borderColor: theme.palette.accent.sky }} />
      <Stack flexDirection={{ xs: "column", md: "row" }} gap={2}>
        <Stack flex={1} paddingTop={1}>
          <Typography variant="h6">
            Scheduled Routine Maintenance
          </Typography>
        </Stack>
        <Stack flex={1}>
          <Stack flex={1} direction="row" alignItems="center" spacing={1}>
            <Stack width="50%">
              <CheckIcon sx={{ color: '#00B6B0', fontSize: 'xx-large'}} />
            </Stack>
            <Stack width="50%">
              <ClearIcon sx={{ color: '#EB0A1E', fontSize: 'xx-large'}} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Divider sx={{ mt: 1, mb: 1, borderColor: theme.palette.accent.sky }} />
      <Stack flexDirection={{ xs: "column", md: "row" }} gap={2}>
        <Stack flex={1} paddingTop={1}>
          <Typography variant="h6">
            Annual Registration
          </Typography>
        </Stack>
        <Stack flex={1}>
          <Stack flex={1} direction="row" alignItems="center" spacing={1}>
            <Stack width="50%">
              <CheckIcon sx={{ color: '#00B6B0', fontSize: 'xx-large'}} />
            </Stack>
            <Stack width="50%">
              <ClearIcon sx={{ color: '#EB0A1E', fontSize: 'xx-large'}} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Divider sx={{ mt: 1, mb: 1, borderColor: theme.palette.accent.sky }} />
      <Stack flexDirection={{ xs: "column", md: "row" }} gap={2}>
        <Stack flex={1} paddingTop={1}> 
          <Typography variant="h6">
            Relief Vehicle
          </Typography>
        </Stack>
        <Stack flex={1}>
          <Stack flex={1} direction="row" alignItems="center" spacing={1}>
            <Stack width="50%">
              <CheckIcon sx={{ color: '#00B6B0', fontSize: 'xx-large'}} />
            </Stack>
            <Stack width="50%">
              <CheckIcon sx={{ color: '#00B6B0', fontSize: 'xx-large'}} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Divider sx={{ mt: 1, mb: 1, borderColor: theme.palette.accent.sky }} />
      <Stack flexDirection={{ xs: "column", md: "row" }} gap={2}>
        <Stack flex={1} paddingTop={1}>
          <Typography variant="h6">
            Roadside Assistance
          </Typography>
        </Stack>
        <Stack flex={1}>
          <Stack flex={1} direction="row" alignItems="center" spacing={1}>
            <Stack width="50%">
              <CheckIcon sx={{ color: '#00B6B0', fontSize: 'xx-large'}} />
            </Stack>
            <Stack width="50%">
              <CheckIcon sx={{ color: '#00B6B0', fontSize: 'xx-large'}} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Divider sx={{ mt: 1, mb: 1, borderColor: theme.palette.accent.sky }} />
      <Stack flexDirection={{ xs: "column", md: "row" }} gap={2}>
        <Stack flex={1} paddingTop={1}>
          <Typography variant="h6">
            Accident Management
          </Typography>
        </Stack>
        <Stack flex={1}>
          <Stack flex={1} direction="row" alignItems="center" spacing={1}>
            <Stack width="50%">
              <CheckIcon sx={{ color: '#00B6B0', fontSize: 'xx-large'}} />
            </Stack>
            <Stack width="50%">
              <ClearIcon sx={{ color: '#EB0A1E', fontSize: 'xx-large'}} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Divider sx={{ mt: 1, mb: 1, borderColor: theme.palette.accent.sky }} />
      <Stack flexDirection={{ xs: "column", md: "row" }} gap={2}>
        <Stack flex={1} paddingTop={1}>
          <Typography variant="h6">
            Warrant of Fitness
          </Typography>
        </Stack>
        <Stack flex={1}>
          <Stack flex={1} direction="row" alignItems="center" spacing={1}>
            <Stack width="50%">
              <CheckIcon sx={{ color: '#00B6B0', fontSize: 'xx-large'}} />
            </Stack>
            <Stack width="50%">
              <ClearIcon sx={{ color: '#EB0A1E', fontSize: 'xx-large'}} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Divider sx={{ mt: 1, mb: 1, borderColor: theme.palette.accent.sky }} />
      <Stack flexDirection={{ xs: "column", md: "row" }} gap={2}>
        <Stack flex={1} paddingTop={1}>
          <Typography variant="h6">
            One set of tyres per 40,000kms
          </Typography>
        </Stack>
        <Stack flex={1}>
          <Stack flex={1} direction="row" alignItems="center" spacing={1}>
            <Stack width="50%">
              <CheckIcon sx={{ color: '#00B6B0', fontSize: 'xx-large'}} />
            </Stack>
            <Stack width="50%">
              <ClearIcon sx={{ color: '#EB0A1E', fontSize: 'xx-large'}} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Divider sx={{ mt: 1, mb: 1, borderColor: theme.palette.accent.sky }} />
      <Stack flexDirection={{ xs: "column", md: "row" }} gap={2}>
        <Stack flex={1} paddingTop={1}>
          <Typography variant="h6">
            Puncture Repairs & Wheel Alignments
          </Typography>
        </Stack>
        <Stack flex={1}>
          <Stack flex={1} direction="row" alignItems="center" spacing={1}>
            <Stack width="50%">
              <CheckIcon sx={{ color: '#00B6B0', fontSize: 'xx-large'}} />
            </Stack>
            <Stack width="50%">
              <ClearIcon sx={{ color: '#EB0A1E', fontSize: 'xx-large'}} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Divider sx={{ mt: 1, mb: 1, borderColor: theme.palette.accent.sky }} />
      <Stack flexDirection={{ xs: "column", md: "row" }} gap={2}>
        <Stack flex={1} paddingTop={1}>
          <Typography variant="h6">
            RUC Management if applicable
          </Typography>
        </Stack>
        <Stack flex={1}>
          <Stack flex={1} direction="row" alignItems="center" spacing={1}>
            <Stack width="50%">
              <CheckIcon sx={{ color: '#00B6B0', fontSize: 'xx-large'}} />
            </Stack>
            <Stack width="50%">
              <ClearIcon sx={{ color: '#EB0A1E', fontSize: 'xx-large'}} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Divider sx={{ mt: 1, mb: 1, borderColor: theme.palette.accent.sky }} />
      <Stack flexDirection={{ xs: "column", md: "row" }} gap={2}>
        <Stack flex={1} paddingTop={1}>
          <Typography variant="h6">
            Insurance Management if applicable
          </Typography>
        </Stack>
        <Stack flex={1}>
          <Stack flex={1} direction="row" alignItems="center" spacing={1}>
            <Stack width="50%">
              <CheckIcon sx={{ color: '#00B6B0', fontSize: 'xx-large'}} />
            </Stack>
            <Stack width="50%">
              <ClearIcon sx={{ color: '#EB0A1E', fontSize: 'xx-large'}} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Divider sx={{ mt: 1, mb: 1, borderColor: theme.palette.accent.sky }} />
      <Stack flexDirection={{ xs: "column", md: "row" }} gap={2}>
        <Stack flex={1} paddingTop={1}>
          <Typography variant="h6">
            Toll Management if applicable
          </Typography>
        </Stack>
        <Stack flex={1}>
          <Stack flex={1} direction="row" alignItems="center" spacing={1}>
            <Stack width="50%">
              <CheckIcon sx={{ color: '#00B6B0', fontSize: 'xx-large'}} />
            </Stack>
            <Stack width="50%">
              <ClearIcon sx={{ color: '#EB0A1E', fontSize: 'xx-large'}} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Divider sx={{ mt: 1, mb: 1, borderColor: theme.palette.accent.sky }} />
      <Stack flexDirection={{ xs: "column", md: "row" }} gap={2}>
        <Stack flex={1} paddingTop={1}>
          <Typography variant="h6">
            FBT Management if applicable
          </Typography>
        </Stack>
        <Stack flex={1}>
          <Stack flex={1} direction="row" alignItems="center" spacing={1}>
            <Stack width="50%">
              <CheckIcon sx={{ color: '#00B6B0', fontSize: 'xx-large'}} />
            </Stack>
            <Stack width="50%">
              <ClearIcon sx={{ color: '#EB0A1E', fontSize: 'xx-large'}} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

LeaseTypeDetails.propTypes = {
  onCancel: PropTypes.func.isRequired,
}

export default LeaseTypeDetails