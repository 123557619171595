import React from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import { Layout } from 'frame'
import PublicPageLayout from './public-page-layout'
import NotFoundImage from 'frame/assets/404.png'


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    position: 'relative',
    marginTop: 40,
    padding: '20px',
    '@media(min-width: 400px)': {
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 100,
    },
  },
  lists: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingTop: '20px',
    width: '100%',
    maxWidth: '400px',
    '@media(min-width: 400px)': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      maxWidth: '360px',
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    width: '100%',
    '@media(min-width: 400px)': {
      width: 'auto',
      minWidth: '30%',
    },
  },
  graphic: {
    display: 'flex',
    width: '100%',
    maxWidth: '740px',
  },
  title: {
    marginTop: '40px',
    marginBottom: '15px',
    color: theme.palette.text.primary,
    fontSize: 20,
    fontStyle: 'italic',
    '@media(min-width: 400px)': {
      fontSize: 40,
      textAlign: 'center',
    },
  },
  subtitle: {
    fontSize: '14px',
    '@media(min-width: 400px)': {
      fontSize: '18px',
      textAlign: 'center',
    },
  },
  link: {
    padding: theme.spacing(0.2, 0),
    ...theme.typography.body1,
    color: theme.palette.text.link,
    fontSize: '14px',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    '@media(min-width: 400px)': {
      padding: theme.spacing(0.4, 0),
      fontSize: '16px',
    },
  },
}))

const Page404 = ({ withoutNav, publicPage }) => {

  const classes = useStyles()
  
  const Page404Content = () => (
    <div className={classes.root}>
      <div className={classes.content}>
        <img className={classes.graphic} src={NotFoundImage} alt="" />
        <Typography gutterBottom className={classes.title} variant="h1">
          Page Not Found
        </Typography>

        {!publicPage && (
          <>
            <Typography className={classes.subtitle} variant="body1">
              Try one of the sections below to find what you were after:
            </Typography>
            <div className={classes.lists}>
              <div className={classes.list}>
                <RouterLink className={classes.link} to={'/dashboard'}>
                  Dashboard
                </RouterLink>
                <RouterLink className={classes.link} to={'/reports/fleet'}>
                  Reports
                </RouterLink>
                <RouterLink className={classes.link} to={'/contracts'}>
                  Contracts
                </RouterLink>
              </div>
              <div className={classes.list}>
                <RouterLink className={classes.link} to={'/drivers'}>
                  Drivers
                </RouterLink>
                <RouterLink className={classes.link} to={'/relief-vehicles'}>
                  Relief Vehicle Requests
                </RouterLink>
                <RouterLink className={classes.link} to={'/vehicle-relocations'}>
                  Vehicle Relocation Requests
                </RouterLink>
                <RouterLink className={classes.link} to={'/vehicle-summary'}>
                  Vehicle Order Summary
                </RouterLink>
              </div>
            </div>
          </>            
        )}
      </div>
    </div>
  )

  return publicPage ? (
    <PublicPageLayout>
      {Page404Content()}
    </PublicPageLayout>
  ) : !withoutNav ? (    
    <Layout>
      {Page404Content()}
    </Layout>
  ) : Page404Content()
}

export default Page404
