import * as z from "zod";

const emailSchema = z.union([
  z.literal(''),
  z.literal('-'),
  z.string().email("Must be a valid email"),
])

export const schema = z.object({
  status: z
    .string({ required_error: "This field is required" })
    .min(1, { message: "This field is required" }),
  effectiveDate: z
    .string()
    .optional(),
  pickup: z
    .string()
    .optional(),
  destination: z
    .string()
    .optional(),
  driverName: z
    .string()
    .optional(),
  driverMobile: z
    .string()
    .refine(value => /^\d{9,11}$/.test(value), {
      message: 'Invalid mobile number',
    })
    .optional()
    .or(z.literal('')),
  contactEmail: emailSchema,
  costCentre: z
    .string()
    .optional(),
  referenceId: z
    .string()
    .optional(),
});
