import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import StyledModalV2 from 'frame/components/styled-modal-v2'
import moment from 'moment'
import { updateServiceHistoryReliableFlag, fetch, reset } from '../../../../actions'
import { useEnabledFeatureToggleList } from 'features/settings'

import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { useProfile } from 'features/account'
import TableCellView from 'frame/components/table-cell/table-cell'

const ServiceHistoryTable = ({ data, onUpdateServiceHistoryReliableFlag, updateOdoData, agreementNo, fleetFlags, onResetFlags }) => {
  const user = useProfile();
  
  const featureToggleList = useEnabledFeatureToggleList()
  const kmExceptionsFeatureToggle = featureToggleList.includes('KMExceptions')

  const [serviceHistory, setServiceHistory] = useState([])
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  useEffect(() => { 
    if(data && JSON.stringify(data) !== JSON.stringify(serviceHistory)) {
      setServiceHistory([...data])
      let lastReading = data.filter(function (e) {
        return e.isReliable;
      });
      if (lastReading.length>0){
        updateOdoData(lastReading[0].serviceDate,lastReading[0].serviceOdo)
      }else{
        updateOdoData("","")
      }
    }
    
  }, [data,serviceHistory]) // eslint-disable-line
  useEffect(() => { 
    if(fleetFlags.fetchServiceHistory === "error") {
      setShowErrorModal(true);
    }
  }, [fleetFlags.fetchServiceHistory])

  const onCrossClick = (item) => {
    // const updatedItem = {...item}
    // updatedItem.isReliable = false;
    // updatedItem["userUpdated"] = user.email;
    // updatedItem["dateUpdated"] = moment();
    // onUpdateServiceHistoryReliableFlag(updatedItem);
  }
  const onTickClick = (item) => {
    // const updatedItem = {...item}
    // updatedItem.isReliable = true;
    // updatedItem["userUpdated"] = user.email;
    // updatedItem["dateUpdated"] = moment();
    // onUpdateServiceHistoryReliableFlag(updatedItem);
  }

  return (
    <>
      <TableContainer
        sx={{
          maxHeight: "301px",
          overflow: "auto",
        }}
      >
        <Table
          stickyHeader aria-label="service history table"
          sx={{
            p: 2,
            borderSpacing: "0 5px",
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                height: "30px",
                borderBottom: "5px solid white"
              }}
            >
              {kmExceptionsFeatureToggle ? ['Date', 'Odo Reading', 'Source', 'Dealership', 'Reliable Odo?'].map((heading, index) => (
                <TableCellView
                  key={`table-heading-${index}`}
                  sx={{ bgcolor: 'white' }}
                >
                  {heading}
                </TableCellView>
              ))
              : ['Date', 'Odo Reading', 'Source', 'Dealership'].map((heading, index) => (
                <TableCellView
                  key={`table-heading-${index}`}
                  sx={{ bgcolor: 'white' }}
                >
                  {heading}
                </TableCellView>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {serviceHistory && serviceHistory.length > 0 ? serviceHistory.map(({ serviceDate, serviceOdo, serviceType, dealership, isReliable }, rowIndex) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={`table-row-${rowIndex}`}
                  sx={{
                    height: "40px",
                    borderBottom: "5px solid white",
                    ...(kmExceptionsFeatureToggle && isReliable !== null && !isReliable && {
                      backgroundColor: "#FDE2E5",
                      "&:hover, &:focus": {
                        backgroundColor: "#FDE2E5 !important" 
                      }
                    }),
                  }}
                >
                  <TableCellView key={`table-serviceDate-column-${rowIndex}`} isFirst>
                    {serviceDate ? moment(serviceDate).format('DD/MM/YYYY') : '-'}
                  </TableCellView>
                  <TableCellView key={`table-serviceOdo-column-${rowIndex}`}>
                    {serviceOdo ? serviceOdo.toLocaleString('en-NZ') : '-'}
                  </TableCellView>
                  <TableCellView key={`table-serviceType-column-${rowIndex}`}>
                    {serviceType}
                  </TableCellView>
                  <TableCellView key={`table-dealership-column-${rowIndex}`}>
                    {dealership}
                  </TableCellView>
                  {kmExceptionsFeatureToggle ? (
                    <TableCellView key={`table-isReliable-column-${rowIndex}`} isLast>
                      <Stack flexDirection="row" alignItems="center" gap={1}>
                      { !isReliable ? (
                        <CancelIcon
                          onClick={() => onCrossClick(serviceHistory[rowIndex])}
                          sx={{
                            width: 22,
                            height: 22,
                            margin: '0px 2px',
                            color: isReliable !== null && !isReliable ? "#EB0A1E" : "#CCCCCC",
                            cursor: 'initial',
                          }}
                        />
                      ) : (
                        <CheckCircleIcon
                          onClick={() => onTickClick(serviceHistory[rowIndex])}
                          sx={{
                            width: 22,
                            height: 22,
                            margin: '0px 2px',
                            color: isReliable ? "#58C021" : "#CCCCCC",
                            cursor: 'initial',
                          }}
                        />
                      )}
                      </Stack>
                    </TableCellView>
                  ):null}
                </TableRow>
              )
            }) : (
              <TableRow>
                <TableCellView>
                  No vehicle history available.
                </TableCellView>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <StyledModalV2
        open={showSuccessModal || showErrorModal}
        onClose={() => {
          setShowErrorModal(false)
          setShowSuccessModal(false)
          onResetFlags()
        }}
        status={showSuccessModal ? "success" : "error"}
        content={
          <Typography variant="h4" textAlign="center">
            You cannot update this record from the portal. Please contact your TFM account manager
          </Typography>
        }
      />
    </>
  )
}

ServiceHistoryTable.propTypes = {
  data: PropTypes.array.isRequired,
  onUpdateServiceHistoryReliableFlag: PropTypes.func.isRequired,
  fleetFlags: PropTypes.object.isRequired,
}

const mapStateToProps = ({ fleet }) => ({
  data: fleet.relatedVehicleHistory,
  fleetFlags: fleet.flags
})
const mapDispatchToProps = (dispatch) => ({
  onUpdateServiceHistoryReliableFlag: (payload) => {
    dispatch(updateServiceHistoryReliableFlag(payload))
  },
  onResetFlags: () => {
    dispatch(reset())
  },
  onFetch: (agreementNo, fleetIds) => {
    const FleetId = fleetIds.join(',')
    dispatch(fetch(null, {
      resourceId: agreementNo,
      queryParams: { FleetId }
    }))
  },
})


export default connect(mapStateToProps,mapDispatchToProps)(ServiceHistoryTable)

