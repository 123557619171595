import React from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import CssBaseline from '@material-ui/core/CssBaseline'
import { BehaviorSubject } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { AuthProvider } from 'auth'
import { getAppConfig } from 'app-config'
import rootEffect from '../effects'
import Routes from '../routes'
import { configureStore, epicMiddleware, history } from '../store'
import GoogleAnalytics from '../components/google-analytics'
import GoogleAnalytics4 from '../components/google-analytics4'
import SimpleReactLightbox from "simple-react-lightbox"
import { LocalizationProvider } from '@material-ui/pickers'
import MomentUtils from '@material-ui/pickers/adapter/moment'
/**
 * Theme
 */
import { ThemeProvider as ThemeProviderV5 } from '@mui/material/styles';
import { ThemeProvider as ThemeProviderV4, StylesProvider } from '@material-ui/core/styles';
import { themeV4 } from 'theme/v4'
import { themeV5 } from 'theme/v5'
import { generateClassName } from "../../theme/v5/createGenerateClassName"

const { store } = configureStore()

const epic$ = new BehaviorSubject(rootEffect)

const hotReloadingEpic = (...args) =>
  epic$.pipe(
    switchMap(epic => epic(...args))
  )

epicMiddleware.run(hotReloadingEpic)

if (module.hot) {
  module.hot.accept('../effects', () => {
    const nextRootEpic = require('../effects').default
    epic$.next(nextRootEpic)
  })
}

const onRedirectCallback = appState => {

  console.log('onRedirectCallback appState', appState)
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

const App = () => {

  const config = getAppConfig()

  return (
    <Provider store={store}>
      <AuthProvider
        domain={config.auth.domain}
        client_id={config.auth.clientId}
        audience={config.auth.audience}
        redirect_uri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
        history={history}>
        <StylesProvider generateClassName={generateClassName}>
          <ThemeProviderV4 theme={themeV4}>
            <ThemeProviderV5 theme={themeV5}>
              <LocalizationProvider dateAdapter={MomentUtils}>
                <ConnectedRouter history={history}>
                  <GoogleAnalytics />
                  <GoogleAnalytics4 />
                  <SimpleReactLightbox>
                    <Routes />
                  </SimpleReactLightbox>
                  <CssBaseline />
                </ConnectedRouter>
              </LocalizationProvider>
            </ThemeProviderV5>
          </ThemeProviderV4>
        </StylesProvider>
      </AuthProvider>
    </Provider>
  )
}

export default App
