import React from "react"
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import MuiTextfield from "../mui-textfield/mui-textfield";

const DatePicker = ({ ...props }) => {
  const mainProps = {...props};
  return (
    <MuiDatePicker
      {...mainProps}
      renderInput={(props) =>
        <MuiTextfield {...props} />
      }
      sx={{
        flex: 1,
        width: "100%",
        "& input": {
          padding: "13px 14px 0",
        },
        "& label": {
          top: mainProps?.value ? "18px" : "0px",
          fontSize: 12,
          color: props?.disabled ? "rgba(0, 0, 0, 0.38)" : "#00B6B0",
        },
        "& label.Mui-focused": {
          top: "18px",
          fontSize: 12,
          color: props?.disabled ? "rgba(0, 0, 0, 0.38)" : "#00AA44",
        },
        "& legend > span": {
          display: "none"
        },
        "& .Mui-disabled": {
          backgroundColor: "#F3F5F8",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: props?.error ? "red !important" : "auto"
        },
        "& .Mui-focused.MuiOutlinedInput-notchedOutline": {
          borderColor: props?.error ? "red !important" : "auto"
        }
      }}
    />
  )
}

export default DatePicker