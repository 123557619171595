import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { trimObjectStrings } from 'utils'
import { fetch, update, deleteVariation, reset, updateVariations } from '../../../actions'
import { useSelectedFleetIds } from 'features/settings'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import VariationDetails from './variation-details'
import VariationRequestsTable from './variations-requests-table'

const Variations = ({ data, flags, errorMessage, userRoles, onFetch, onUpdateVariations, onDeleteVariation, onReset }) => {
  const fleetIds = useSelectedFleetIds()
  const theme = useTheme()

  const [loading, setLoading] = useState(false)
  const [updatingStatus, setUpdatingStatus] = useState(false)

  useEffect(() => {
    setLoading(true)
    onReset()
    onFetch(data.agreementNo, fleetIds)
    console.log('Variations tab onload data: ', { variations: data.variations, data }) // for testing
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if(flags.deleteVariation === 'processed') {
      onFetch(data.agreementNo, fleetIds)
    }// eslint-disable-next-line
  }, [flags.deleteVariation])
  
  useEffect(() => {
    if(loading && flags.fetch === 'processed') {
      setLoading(false)
    }// eslint-disable-next-line
  }, [flags.fetch])

  const maturityDateExpired = !!data.maturityDate && dayjs(data.maturityDate) < dayjs()
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Card
        sx={{
          boxShadow: "none",
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomRightRadius: "5px",
          borderBottomLeftRadius: "5px",
        }}
      >
        <CardContent
          sx={{
            p: 3
          }}
        >
          <Stack gap={2}>
            <Stack gap={2}>
              <Stack>
                <Typography variant="h5" fontWeight={600}>
                  Existing Contract
                </Typography>
              </Stack>
              <VariationDetails
                maturityDateExpired={maturityDateExpired}
                items={[
                  { label: "Agreement Type", value: data.agreementType ?? "-" },
                  { label: "Term", value: data.term ?? "-" },
                  { label: "Contract KM's", value: data.contractKm ? parseInt(data.contractKm, 10).toLocaleString('en-NZ') : "-" },
                  { label: "Maturity Date", value: data.maturityDate ? dayjs(data.maturityDate).format('Do MMMM YYYY') : "-" },
                  { label: "Tyres", value: data.noOfTyres ?? "-" },
                ]}
              />
            </Stack>

            <Stack gap={2}>
              <Stack>
                <Typography variant="h5" fontWeight={600}>
                  Variation Requests
                </Typography>
                <Typography variant="bodyMedium" fontWeight={300}>
                  Request up to 3 variation quotes
                </Typography>
              </Stack>
              <Stack>
                <VariationRequestsTable
                  isLoading={loading}
                  readOnly={maturityDateExpired}
                  data={data}
                  flags={{ ...flags, updatingStatus }}
                  errorMessage={errorMessage} 
                  userRoles={userRoles}
                  onUpdate={onUpdateVariations}
                  onDeleteVariation={onDeleteVariation}
                  setUpdatingStatus={setUpdatingStatus}
                />
              </Stack>
            </Stack>
          </Stack>

          {/* <Typography  gutterBottom variant="h4">
            Variations
          </Typography>

          <Typography  gutterBottom variant="h5" className={classes.heading}>
            Existing Contract
          </Typography>

          <div className={classes.content}>
            <Grid container spacing={3}>
              {[
                { label: 'Agreement Type', value: data.agreementType },
                { label: 'Term', value: data.term },
                { label: "Contract KM's", value: data.contractKm ? parseInt(data.contractKm, 10).toLocaleString('en-NZ') : '-' },
                { label: 'Maturity Date', value: data.maturityDate ? moment(data.maturityDate).format('Do MMMM YYYY') : '-' },
                { label: 'Tyres', value: data.noOfTyres },
              ]
              .map(({ label, value }, index) => (
                <Grid item xs={12} key={`existing-contract-details-${index}`}>
                  <RowValueText label={label}>
                    <Typography variant="body1">
                      {value || '-'}
                    
                      {label === 'Maturity Date' && maturityDateExpired && (
                        <Label color={colors.red[600]} variant="outlined" className={classes.expired}>
                          Expired
                        </Label>
                      )}
                    </Typography>
                  </RowValueText>
                </Grid>
              ))}

            </Grid>
          </div>

          <Typography  gutterBottom variant="h5" className={clsx(classes.heading, classes.padding)}>
            Variation Requests (Request up to 3 variation quotes)
          </Typography>
          {loading || flags.update === 'processing' || flags.deleteVariation === 'processing' ? (
            <PageLoader minHeight={180} />
          ) : (
            <VariationRequestsTable
              readOnly={maturityDateExpired}
              data={data}
              flags={{ ...flags, updatingStatus }}
              errorMessage={errorMessage} 
              userRoles={userRoles}
              onUpdate={onUpdateVariations}
              onDeleteVariation={onDeleteVariation}
              setUpdatingStatus={setUpdatingStatus}
            />
          )} */}
          
        </CardContent>
      </Card> 
    </LocalizationProvider>
  )
}

Variations.propTypes = {
  data: PropTypes.object.isRequired,
  flags: PropTypes.object.isRequired,
  errorMessage: PropTypes.string.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  // func
  onFetch: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onUpdateVariations: PropTypes.func.isRequired,
  onDeleteVariation: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
}

const mapStateToProps = ({ fleet, settings }, ownProps) => ({
  data: trimObjectStrings(fleet.data?.[ownProps.match.params.id]), // Because the API does not clean data for us
  flags: fleet.flags,
  errorMessage: fleet.errorMessage || '',
  userRoles: settings.roles,
})

const mapDispatchToProps = (dispatch) => ({
  onFetch: (agreementNo, fleetId) => {
    dispatch(fetch(null, {
      resourceId: agreementNo,
      queryParams: { fleetId }
    }))
  },
  onUpdate: (payload) => {
    dispatch(update(payload))
  },
  onUpdateVariations: (payload) => {
    dispatch(updateVariations(payload))
  },
  onDeleteVariation: (id, fleetId) => {
    dispatch(deleteVariation(null, {
      resourceId: id,
      queryParams: { fleetId }
    }))
  },
  onReset: () => {
    dispatch(reset())
  },
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Variations))