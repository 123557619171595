import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import RowActions from './row-options'
import { createRowSelector, useRouter } from 'utils'
import { FleetIcon } from 'frame/components'
import { types } from '../../module'
import clip from 'text-clipper'
import { useEnabledFeatureToggleList } from 'features/settings'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'

import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import { themeV5 } from 'theme/v5'

import TableCellView from 'frame/components/table-cell/table-cell'

const Row = ({ item, columns, onView }) => {
  const { history, location } = useRouter()

  const featureToggleList = useEnabledFeatureToggleList()
  const onSafeRowClick = createRowSelector(onView)
  const documentsReportFeatureToggle = featureToggleList.includes('DocumentsViewing')
  const tfp1944FeatureToggle = featureToggleList.includes('TFP1944')

  const onViewDocumentsReport = () => {
    history.push(`/reports/documents?registration=${item.registration}`, { label: 'Incident Management',  path: location.pathname })
  }

  return (
    <TableRow
      hover
      sx={{
        boxSizing: 'border-box',
        cursor: 'pointer',
        verticalAlign: 'middle',
        height: '30px',
        borderBottom: '5px solid white',
      }}
      onClick={onSafeRowClick}
    >
      <TableCell sx={{ bgcolor: 'white', width: '10px', p: 0 }} />
      
      {columns.accountName && (
        <TableCellView isFirst>
          <FleetIcon fleetName={item.accountName} />
        </TableCellView>
      )}
      {columns.driverNote && tfp1944FeatureToggle && (
        <TableCellView>
          {item.driverNote || '-'}
        </TableCellView>
      )}      
      {columns.driver && (
        <TableCellView>
          {item.driver || '-'}
        </TableCellView>
      )}
      {columns.costCentre && (
        <TableCellView>
          {item.costCentre || '-'}
        </TableCellView>
      )}      
      {columns.incidentType && (
        <TableCellView>
          {item.incidentType || '-'}
        </TableCellView>
      )}
      {columns.incidentDate && (
        <TableCellView>
          {item.incidentDate ? moment(item.incidentDate).format('Do MMMM YYYY h:mm A') : '-'}
        </TableCellView>
      )}
      {columns.description && (
        <TableCellView>
          {item.description
            ? clip(item.description, 100)
            : '-'
          }
        </TableCellView>
      )}
      {columns.registration && (
        <TableCellView>
          {item.registration || '-'}
        </TableCellView>
      )}
      {columns.year && (
        <TableCellView>
          {item.year || '-'}
        </TableCellView>
      )}
      {columns.make && (
        <TableCellView>
          {item.make || '-'}
        </TableCellView>
      )}
      {columns.model && (
        <TableCellView>
          {item.model || '-'}
        </TableCellView>
      )}
      {columns.variant && (
        <TableCellView>
          {item.variant || '-'}
        </TableCellView>
      )}
      {columns.attachmentsCount && (
        <TableCellView>
          {item.attachmentsCount || '-'}
        </TableCellView>
      )}
      {columns.documentsCount && documentsReportFeatureToggle && (
        <TableCellView>
          {( item.documentsCount > 0 ? (            
            <Tooltip title="View documents">
              <IconButton
                onClick={(event) => {
                  event.stopPropagation(); // Prevents the TableRow onClick from being triggered
                  onViewDocumentsReport();
                }}
              >
                <InsertDriveFileOutlinedIcon sx={{ color: themeV5.palette.primary.main }} />
              </IconButton>
            </Tooltip>
          ) : '-')}
        </TableCellView>
      )}
      {columns.notes && (
        <TableCellView>
          {item.notes || '-'}
        </TableCellView>
      )}
      <TableCellView sx={{ maxWidth: "50px", width: "50px" }}>
        <RowActions          
          className="ignore"
          id={item.id}
          onView={onView}
        />
      </TableCellView>
      {Object.values(columns).some(value => value === true) &&            
        (<TableCellView isLast></TableCellView>)
      }
    </TableRow>
  )
}

Row.propTypes = {
  item: types.ModelType.isRequired,
  columns: types.ColumnsType.isRequired,
  onView: PropTypes.func.isRequired,
}

export default Row
