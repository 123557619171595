import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { getDateColor, getTrackingColor, obfuscateAgreementNumber } from 'listable'
import { Label, FleetIcon } from 'frame/components'
import RowActions from './row-options'
import { createRowSelector, useRouter } from 'utils'
import { types } from '../../module'
import { useEnabledFeatureToggleList } from 'features/settings'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'

import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import { themeV5 } from 'theme/v5'

import TableCellView from 'frame/components/table-cell/table-cell'

const Row = ({ item, columns, onView }) => {
  const { history, location } = useRouter()
  const onSafeRowClick = createRowSelector(onView)

  const featureToggleList = useEnabledFeatureToggleList()
  const documentsReportFeatureToggle = featureToggleList.includes('DocumentsViewing')
  const tfp1944FeatureToggle = featureToggleList.includes('TFP1944') // driver note column in report
  const onViewDocumentsReport = () => {
    history.push(`/reports/documents?registration=${item.registration}`, { label: 'KM Tracking',  path: location.pathname })
  }
  
  return (
    <TableRow
      hover
      sx={{
        boxSizing: 'border-box',
        cursor: 'pointer',
        verticalAlign: 'middle',
        height: '30px',
        borderBottom: '5px solid white',
      }}
      onClick={onSafeRowClick}
    >
      <TableCell sx={{ bgcolor: 'white', width: '10px', p: 0 }} />
      {columns.accountName && (
        <TableCellView isFirst>
          <FleetIcon fleetName={item.accountName}/>
        </TableCellView>
      )}
      {columns.agreementNo && (
        <TableCellView>
          {obfuscateAgreementNumber(item.agreementNo || '-', true)}
        </TableCellView>
      )}
      {columns.registration && (
        <TableCellView>
          {item.registration || '-'}
        </TableCellView>
      )}
      {columns.driverNote && tfp1944FeatureToggle && (
        <TableCellView>
          {item.driverNote || '-'}
        </TableCellView>
      )} 
      {columns.driver && (
        <TableCellView>
          {item.driver || '-'}
        </TableCellView>
      )}
      {columns.year && (
        <TableCellView>
          {item.year || '-'}
        </TableCellView>
      )}
      {columns.make && (
        <TableCellView>
          {item.make || '-'}
        </TableCellView>
      )}
      {columns.model && (
        <TableCellView>
          {item.model || '-'}
        </TableCellView>
      )}
      {columns.variant && (
        <TableCellView>
          {item.variant || '-'}
        </TableCellView>
      )}
      {columns.fuelType && (
        <TableCellView>
          {item.fuelType || '-'}
        </TableCellView>
      )}
      {columns.costCentre && (
        <TableCellView>
          {item.costCentre || '-'}
        </TableCellView>
      )}
      {columns.lastOdometerReading && (
        <TableCellView>
          {item.lastOdometerReading ? moment(item.lastOdometerReading).format('DD/MM/YY') : '-'}
        </TableCellView>
      )}
      {columns.odometer && (
        <TableCellView>
          {item.odometer ? Number(item.odometer).toLocaleString('en-NZ') : '-'}
        </TableCellView>
      )}
      {columns.forecastEndKm && (
        <TableCellView>
          {item.forecastEndKm ? Number(item.forecastEndKm).toLocaleString('en-NZ') : '-'}
        </TableCellView>
      )}
      {columns.contractEndKm && (
        <TableCellView>
          {item.contractEndKm ? Number(item.contractEndKm).toLocaleString('en-NZ') : '-'}
        </TableCellView>
      )}
      {columns.tracking && (
        <TableCellView>
          {item.tracking ? (
            <Label
              color={getTrackingColor(item.tracking)}
              variant="outlined">
              {item.tracking}
            </Label>
          ) : '-'}
        </TableCellView>
      )}
      {columns.kmVariation && (
        <TableCellView>
          {item.kmVariation ? (
            <Label
              // {color={getForecastVariationColor(item.forecastEndKm, item.contractEndKm)}
              color={getTrackingColor(item.tracking)}>
              {Number(item.kmVariation).toLocaleString('en-NZ')}
            </Label>
          ) : '-'}
        </TableCellView>
      )}
      {columns.odometerSource && (
        <TableCellView>
          {item.odometerSource || '-'}
        </TableCellView>
      )}
      {columns.maturityDate && (
        <TableCellView>
          {item.maturityDate ? (
            <Label
              color={getDateColor(item.maturityDate)}
              variant="outlined">
              {moment(item.maturityDate).format('DD/MM/YY')}
            </Label>
          ) : '-'}
        </TableCellView>
      )}
      {columns.startDate && (
        <TableCellView>
          {item.startDate ? moment(item.startDate).format('DD/MM/YY') : '-'}
        </TableCellView>
      )}
      {columns.agreementType && (
        <TableCellView>
          {item.agreementType || '-'}
        </TableCellView>
      )}

      {columns.documentsCount && documentsReportFeatureToggle && (
        <TableCellView>
          {( item.documentsCount > 0 ? (            
            <Tooltip title="View documents">
              <IconButton
                onClick={(event) => {
                  event.stopPropagation(); // Prevents the TableRow onClick from being triggered
                  onViewDocumentsReport();
                }}
              >
                <InsertDriveFileOutlinedIcon sx={{ color: themeV5.palette.primary.main }} />
              </IconButton>
            </Tooltip>
          ) : '-')}
        </TableCellView>
      )}
      {Object.values(columns).some(value => value === true) &&            
        (<TableCellView isLast></TableCellView>)
      }
    </TableRow>
  )
}

Row.propTypes = {
  item: types.ModelType.isRequired,
  columns: types.ColumnsType.isRequired,
  onView: PropTypes.func.isRequired,
}

export default Row
