import * as z from "zod";

export const schema = z.object({
  // make: z
  //   .string({ required_error: "Make is required" })
  //   .min(1, { message: "Make is required" }),
  // model: z
  //   .string({ required_error: "Model is required" })
  //   .min(1, { message: "Model is required" }),
  make: z
    .string()
    .optional(),
  model: z
    .string()
    .optional(),
  variant: z
    .string()
    .optional(),
  colour: z
    .string()
    .optional(),
  driveTrain: z
    .string()
    .optional(),
  vehicleNotes: z
    .string()
    .optional(),
});
