import React from 'react'
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Link from "@mui/material/Link"
import { useTheme } from "@mui/material/styles"
import clip from 'text-clipper'

const IndustryNewsItem = ({
  imageUri,
  title,
  teaser,
  uri,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        pt: 1
      }}
    >
      <Link
        href={uri}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          textDecoration: 'none'
        }}
      >
        <Grid
          container
          sx={{
            cursor: 'pointer',
            padding: 1,
          }}
        >
          <Grid
            item
            xs={12}
            sm={2}
            md={3}
            sx={{
              textAlign: 'left',
              [theme.breakpoints.down(1500)]: { textAlign: 'center' },
            }}
          >
            <Box
              component='img'
              src={imageUri}
              alt={`Article ${title}`}
              sx={{
                width: {xs: '100%', md: '80px' , xl: '100px'},
                height: 'auto',
                backgroundPositionX: 'top left',
                marginBottom: { xs: 1, sm: 0 },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
            md={9}
          >
            <Box
              sx={{
                paddingLeft: 0,
                [theme.breakpoints.down(1500)]: { paddingLeft: theme.spacing(1) },
                [theme.breakpoints.down('xs')]: { paddingLeft: 0 }
              }}
            >
              <Typography
                variant="h4" 
                sx={{
                  fontWeight: 'bold',
                  paddingBottom: 1,
                  '&:hover': { textDecoration: 'underline' },
                }}
              >
                {title}
              </Typography>
              <Typography
                variant="body2"
                color= '#000000'
                style={{ userSelect: 'none' }}
              >
                {clip(teaser, 100)}
                <br />
                <Typography
                  variant='body2'
                  sx={{
                    color: '#0000FF',
                    textDecoration: 'none',
                    cursor: 'pointer',
                    '&:hover': { textDecoration: 'underline' },
                  }}
                >
                  Read More
                </Typography>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Link>
    </Box>
  )
}

export default IndustryNewsItem