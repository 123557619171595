import React, { Fragment, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'

import {
  ListItemIcon,
  ListItemText,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core'

import DeleteIcon from "@material-ui/icons/Delete"
import MoreIcon from '@material-ui/icons/MoreVert'
import ViewIcon from '@material-ui/icons/Subject'
import { hasRoles } from 'acl'
import { useEnabledFeatureToggleList } from 'features/settings'
import StyledModalV2, { StyledModalV2Actions } from "frame/components/styled-modal-v2"

import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

const useStyles = makeStyles(() => ({}))

const Options = ({ onView, onDelete, userRoles, status, ...props }) => {

  const classes = useStyles()
  const moreRef = useRef(null)
  const [openMenu, setOpenMenu] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  
  const featureToggleList = useEnabledFeatureToggleList()
  const userPermissionsFeatureToggle = featureToggleList.includes('UserPermissions')
  const viewEdit = status === 'Submitted' ? 'View' : 'Edit'

  const handleMenuOpen = () =>
    setOpenMenu(true)

  const handleMenuClose = () =>
    setOpenMenu(false)

  const onViewHandler = () => {
    handleMenuClose()
    onView()
  }

  const onDeleteHandler = () => {
    handleMenuClose()
    setOpenDeleteModal(true)
  }

  const onDeleteHandlerModal = () => {
    handleMenuClose()
    onDelete()
    setOpenDeleteModal(false)
  }

  return (
    <Fragment>
      <Tooltip title="More options">
        <IconButton
          {...props}
          onClick={handleMenuOpen}
          ref={moreRef}
          size="small">
          <MoreIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={moreRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        classes={{ paper: classes.menu }}
        onClose={handleMenuClose}
        open={openMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <MenuItem onClick={onViewHandler}>
          <ListItemIcon>
            <ViewIcon />
          </ListItemIcon>
          <ListItemText primary={viewEdit} />
        </MenuItem>
        {/* {hasRoles(['superadmin', 'fleetcontroller'], userRoles) && onDelete && !isAlfaDown && ( */}
        {onDelete && status === 'Draft' && userPermissionsFeatureToggle && hasRoles(['superadmin','fleetcontroller'], userRoles) && ( 
          <MenuItem
            onClick={onDeleteHandler}
          >
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText primary="Delete" />
          </MenuItem>
        )}
        {onDelete && status === 'Submitted' && userPermissionsFeatureToggle && hasRoles(['superadmin'], userRoles) && ( 
          <MenuItem
            onClick={onDeleteHandler}
          >
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText primary="Delete" />
          </MenuItem>
        )}
      </Menu>
      <StyledModalV2
        open={openDeleteModal}
        title="Delete Vehicle Order"
        content={
          <Stack justifyContent="center" alignItems="center" gap={1}>
            <Typography variant="bodyLarge">
              IMPORTANT: Please confirm only if you want to delete this vehicle order.
            </Typography>
          </Stack>
        }
        actions={
          <StyledModalV2Actions
            onCancel={() => setOpenDeleteModal(false)}
            onConfirm={onDeleteHandlerModal}
          />
        }
      />
    </Fragment>
  )
}

Options.propTypes = {
  className: PropTypes.string,
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  onView: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  status: PropTypes.string,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default Options
