import * as z from "zod";

export const schema = z.object({
  hasFuelCardAndReporting: z
    .boolean()
    .optional(),
  hasReliefVehicle: z
    .boolean()
    .optional(),
  hasRucManagement: z
    .boolean()
    .optional(),
  hasAccidentManagement: z
    .boolean()
    .optional(),
  hasTollManagement: z
    .boolean()
    .optional(),
  hasInfringementManagement: z
    .boolean()
    .optional(),
  hasFbtReporting: z
    .boolean()
    .optional(),
  hasTelematics: z
    .boolean()
    .optional(),
  hasFleetPortal: z
    .boolean()
    .optional(),
  hasDriverApp: z
    .boolean()
    .optional(),
  hasRoadsideAssistance: z
    .boolean()
    .optional(),
  additionalServiceNotes: z
    .string()
    .optional(),
});
