import * as z from "zod";

const emailSchema = z.union([
  z.string()
  .min(1, { message: "This field is required" })
  .email("Must be a valid email"),
])

export const schema = z.object({
  effectiveDate: z
    .date().nullable().refine(date => date !== null, { message: "Please enter a valid date" })
    .optional(),
  pickup: z
    .string({ required_error: "This field is required" })
    .min(1, { message: "This field is required" }),
  destination: z
    .string({ required_error: "This field is required" })
    .min(1, { message: "This field is required" }),
  driverName: z
    .string({ required_error: "This field is required" })
    .min(1, { message: "This field is required" }),
  driverMobile: z
    .string()
    .min(1, { message: "This field is required" })
    .refine(value => /^\d{9,11}$/.test(value), {
      message: 'Invalid mobile number',
    }),
  contactEmail: emailSchema,
  costCentre: z
    .string({ required_error: "This field is required" })
    .min(1, { message: "This field is required" }),
});
