import * as z from "zod";

export const schema = z.object({
  hasCanopySlideWindows: z
    .boolean()
    .optional(),
  hasCanopyLiftupWindows: z
    .boolean()
    .optional(),
  hasCentralLockingForCanopySelection: z
    .boolean()
    .optional(),
  hasRubberMats: z
    .boolean()
    .optional(),
  hasCarpetMats: z
    .boolean()
    .optional(),
  hasDeckliner: z
    .boolean()
    .optional(),
  hasHardlid: z
    .boolean()
    .optional(),
  hasSoftTonneauCover: z
    .boolean()
    .optional(),
  hasTowbarWiring: z
    .boolean()
    .optional(),
  hasRoofRacks: z
    .boolean()
    .optional(),
  hasSeatCovers: z
    .boolean()
    .optional(),
  hasFirstAidKit: z
    .boolean()
    .optional(),
  hasFireExtinguisher: z
    .boolean()
    .optional(),
  hasCargoBarrier: z
    .boolean()
    .optional(),
  hasTyreUpgrade: z
    .boolean()
    .optional(),
  hasSignWriting: z
    .boolean()
    .optional(),
  hasSportsBar: z
    .boolean()
    .optional(),
  hasNudgeBar: z
    .boolean()
    .optional(),
  canopySlideWindowsNotes: z
    .string()
    .optional(),
  canopyLiftupWindowsNotes: z
    .string()
    .optional(),
  centralLockingForCanopySelectionNotes: z
    .string()
    .optional(),
  rubberMatsNotes: z
    .string()
    .optional(),
  carpetMatsNotes: z
    .string()
    .optional(),
  decklinerNotes: z
    .string()
    .optional(),
  hardlidNotes: z
    .string()
    .optional(),
  softTonneauCoverNotes: z
    .string()
    .optional(),
  towbarWiringNotes: z
    .string()
    .optional(),
  roofRacksNotes: z
    .string()
    .optional(),
  seatCoversNotes: z
    .string()
    .optional(),
  firstAidKitNotes: z
    .string()
    .optional(),
  fireExtinguisherNotes: z
    .string()
    .optional(),
  cargoBarrierNotes: z
    .string()
    .optional(),
  tyreUpgradeNotes: z
    .string()
    .optional(),
  signWritingNotes: z
    .string()
    .optional(),
  sportsBarNotes: z
    .string()
    .optional(),
  nudgeBarNotes: z
    .string()
    .optional(),
  otherAccessories: z
    .string()
    .optional(),
});
