import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Divider from '@mui/material/Divider';

const StatusMenu = ({
  options,
  onChange,
  onDelete,
}) =>  {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="status-button"
        aria-controls={open ? 'status-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="status-menu"
        MenuListProps={{
          'aria-labelledby': 'status-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        on
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            sx={{
              fontSize: 12,
              fontWeight: 500,
              textTransform: "capitalize",
              width: "100%"
            }}
            component="button"
            onClick={() => {
              onChange(option)
              handleClose()
            }}
          >
            {option}
          </MenuItem>
        ))}
        <MenuItem
          sx={{
            fontSize: 12,
            fontWeight: 500,
            textTransform: "capitalize",
            width: "100%"
          }}
          component="button"
          onClick={() => {
            onDelete()
            handleClose()
          }}
        >
          delete
        </MenuItem>
      </Menu>
    </div>
  );
}

export default StatusMenu