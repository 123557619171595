import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { capitalize } from 'utils'
import { Alert, LookupField, RegistrationLookupWrapper } from 'frame/components'
import { createDefaultModel } from '../../module'
import { hasRoles } from 'acl'
import { useEnabledFeatureToggleList } from 'features/settings'
import { getReliefVehicleTypes } from 'utils/reliefVehicleTypes'
import { useForm, Controller, FormProvider } from 'react-hook-form'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import DatePicker from 'frame/components/date-picker-v5'
import { useTheme } from '@mui/material/styles'

import DetailsInfoWrapper from 'frame/components/details-info-wrapper'
import CardContent from 'frame/components/card-content'
import MuiTextfield from 'frame/components/mui-textfield'
import MuiFormSelect from 'frame/components/mui-form-select'
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import { zodResolver } from '@hookform/resolvers/zod'
import { schema } from '../../components/item/form-schema'

const Form = ({
  relatedContract,
  disabled,
  error,
  fetchContractStatus,
  fleetIds,
  onClose,
  onFetchContract,
  onSubmit,
  onLookup,
  errorMessage,
  userRoles
}) => {

  const form = useRef()
  const theme = useTheme()
  const [contract, setContract] = useState({})
  const [vehicle, setVehicle] = useState()
  const [values, setValues] = useState(createDefaultModel())
  const featureToggleList = useEnabledFeatureToggleList()
  const userPermissionsFeatureToggle = featureToggleList.includes('UserPermissions')
  const disableButtons = userPermissionsFeatureToggle && hasRoles(['fleetcoordinator'], userRoles)

  const lookupContractData = relatedContract?.[vehicle?.agreementNo] || null
  const locked = disabled || ! contract?.agreementNo ? true : false

  const hasContract = !!contract?.agreementNo

  const methods = useForm({
    resolver: zodResolver(schema),
    reValidateMode: "all",
    defaultValues: {
      pickupAddress: values?.pickupAddress ?? "",
      vehicleType: values?.vehicleType ?? "",
      collectionDate: values?.collectionDate ?? null,
      bookedReturnDate: values?.bookedReturnDate ?? null,
    }
  })

  const onRegoChange = (option) => {
    setContract({})
    setVehicle(option)
    if (option?.agreementNo) {
      onFetchContract(option.agreementNo, fleetIds)
    }
  }

  const onInputChange = ({ target: { id, name, value } }) => {
    setValues({ ...values, [id || name]: value })
    methods.setValue(name, value, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const onSelectChange = (key, value) => {
    setValues({
      ...values,
      [key]: value,
    })
    methods.setValue(key, value, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const onSubmitForm = () => {
    if (values.collectionDate && values.bookedReturnDate && values.vehicleType && values.pickupAddress) {
      onSubmit(values)
    }
  }

  const tfp1865FeatureToggle = featureToggleList.includes('TFP1865');
  let vehicleTypes = getReliefVehicleTypes(tfp1865FeatureToggle);

  const getMaxReturnDate = () => {
    if (hasContract && contract.maturityDate) {
      return moment(contract.maturityDate)
    }
    return null
  }

  // Similar to getDerivedStateFromProps
  // We need to update local state here once we receive
  // the corresponding contract.
  // So we set the contract and add appropriate contract values to the
  // relief vehicle request as is needed.
  useEffect(() => {
    if (lookupContractData?.agreementNo) {
      console.log('[lookupContractData]', { vehicle, lookupContractData })
      setContract(lookupContractData)
      setValues({
        ...values,
        driver: lookupContractData.driver || '',
        driverMobile: lookupContractData.driverMobile || '',
        driverEmail: lookupContractData.driverEmail || '',
        agreementNo: lookupContractData.agreementNo || '',
        thirdPartyNumber: lookupContractData.thirdPartyNumber || '',
        registration: lookupContractData.registration || '',
      })
    }
  }, [lookupContractData]) // eslint-disable-line

  const expiredMaturityDate = moment(contract.maturityDate) <= moment()

  // to ensure description are in same cases
  const correctCases = (str) =>
    capitalize(str.trim().toLowerCase())

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormProvider>
          <form
            id="request-relief-vehicle-form"
            onSubmit={methods.handleSubmit(onSubmitForm)}
          >
          <CardContent>
            {error && (
              <Stack gap={1}>
                <Alert>
                  {errorMessage || 'Something went wrong while trying to submit relief vehicle request.' }
                </Alert>
              </Stack>
            )}

            <Stack gap={4}>
              <Stack gap={2}>
                <Typography variant="h4" fontWeight={600}>
                  Vehicle
                </Typography>
                <Typography variant="bodyLarge">
                  Please select the vehicle you wish to book a relief vehicle for.
                </Typography>
                <Stack flex={1} gap={1}>
                  <RegistrationLookupWrapper
                    lookupContractData={contract}
                    fetchContractStatus={fetchContractStatus}>
                    <LookupField
                      id="registration"
                      disabled={disabled || disableButtons}
                      fullWidth
                      label="Vehicle Registration"
                      lookupPath="vehicleregistration"
                      value={vehicle}
                      variant="outlined"
                      validators={['required']}
                      errorMessages={['This field is required']}
                      onChange={onRegoChange}
                      onLookup={onLookup}
                      resourceModel="requestReliefVehicles"/>
                  </RegistrationLookupWrapper>
                </Stack>
              </Stack>
              {hasContract && (
                <>
                  <Stack gap={1}>
                    <DetailsInfoWrapper
                      dataId="relief-vehicle-booking"
                      items={[{
                        label: "Registration",
                        value: contract.registration || '-'
                      },
                      {
                        label: "Description",
                        value: contract.make && contract.model ?
                              correctCases(contract.make) + ' ' + correctCases(contract.model)
                              : '-',
                      },
                      {
                        label: "Cost Centre",
                        value: contract.costCentre || '-',
                      },
                      {
                        label: "Maturity Date",
                        value: contract.maturityDate || '-',
                        format: "Do MMMM YYYY",
                      },
                      {
                        label: "Driver",
                        value: contract.driver || '-',
                      },
                      {
                        label: "Controller",
                        value: contract.controller || '-',
                      },
                    ]}
                    />
                  </Stack>
                </>
              )}
              <Stack gap={2}>
                <Typography variant="h4" fontWeight={600}>
                  Booking details
                </Typography>
                <Typography variant="bodyLarge">
                  Please complete all required fields.
                </Typography>
                <Stack flexDirection={{ xs: "column", md: "row" }} gap={1}>
                  <Stack width="50%">
                    <Controller
                      name="vehicleType"
                      control={methods.control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <MuiFormSelect
                          {...field}
                          id="vehicleType"
                          disabled={locked || expiredMaturityDate}
                          label="Vehicle Type"
                          labelId="vehicleType-label"
                          options={vehicleTypes}
                          value={values.vehicleType || ""}
                          onChange={(event) =>
                            onSelectChange("vehicleType", event?.target?.value)
                          }
                        />
                      )}
                    />
                    <Typography color="error" fontSize={12}>
                      {methods.formState.errors?.vehicleType?.message}
                    </Typography>
                  </Stack>
                  <Stack width="50%">
                    <Controller
                      name="pickupAddress"
                      control={methods.control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <MuiTextfield
                          {...field}
                          id="pickupAddress"
                          name="pickupAddress"
                          disabled={locked || expiredMaturityDate}
                          error={Boolean(methods.formState.errors?.pickupAddress?.message)}
                          label="Pickup Address"
                          value={values.pickupAddress || ''}
                          onChange={onInputChange}
                        />
                      )}
                    />
                    <Typography color="error" fontSize={12}>
                      {methods.formState.errors?.pickupAddress?.message}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack flexDirection={{ xs: "column", md: "row" }} gap={1}>
                  <Stack width="50%">
                    <DatePicker
                      label="Start Date"
                      format="DD/MM/YYYY"
                      minDate={dayjs()}
                      maxDate={getMaxReturnDate()}
                      value={typeof values.collectionDate === "undefined" ? null : values?.collectionDate}
                      disabled={locked || expiredMaturityDate}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          collectionDate: value,
                        })
                        methods.setValue("collectionDate", dayjs(value).toDate(), {
                          shouldValidate: true,
                          shouldDirty: true,
                        })
                      }}
                    />
                    <Typography color="error" fontSize={12}>
                      {methods.formState.errors?.collectionDate?.message}
                    </Typography>
                  </Stack>
                  <Stack width="50%">
                    <DatePicker
                      label="End Date"
                      format="DD/MM/YYYY"
                      minDate={dayjs()}
                      maxDate={getMaxReturnDate()}
                      value={typeof values.bookedReturnDate === "undefined" ? null : values?.bookedReturnDate}
                      disabled={locked || expiredMaturityDate}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          bookedReturnDate: value,
                        })
                        methods.setValue("bookedReturnDate", dayjs(value).toDate(), {
                          shouldValidate: true,
                          shouldDirty: true,
                        })
                      }}
                    />
                    <Typography color="error" fontSize={12}>
                      {methods.formState.errors?.bookedReturnDate?.message}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack gap={1} flex={1}>
                  <Controller
                    name="comments"
                    control={methods.control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <>
                        <MuiTextfield
                          {...field}
                          multiline
                          rows={10}
                          id="comments"
                          disabled={locked || expiredMaturityDate}
                          error={Boolean(methods.formState.errors?.comments?.message)}
                          label="Comments"
                          value={values.comments || ""}
                          onChange={onInputChange}
                        />
                        <small>Please include contact name, email address and mobile number.</small>
                      </>
                    )}
                  />
                </Stack>
              </Stack>
            </Stack>

            <Divider sx={{ mt: 3, mb: 2, borderColor: theme.palette.accent.sky }} />
            <Stack
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent="space-between"
              rowGap={2}
            >
              <Box width={{ xs: "100%", md: "205px" }}>
                <Button
                  fullWidth
                  color="error"
                  variant="outlined"
                  onClick={onClose}
                >
                  Close
                </Button>
              </Box>
              <Stack flexDirection="row" gap={1}>
                
                <Box width={{ xs: "100%", md: "205px" }}>
                  <Button
                    fullWidth
                    color="error"
                    variant="outlined"
                    disabled={locked}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </Box>
                <Box width={{ xs: "100%", md: "205px" }}>
                  <Button
                    type="submit"
                    fullWidth
                    color="primary"
                    disabled={locked || expiredMaturityDate}
                    variant="contained"
                  >
                    Request Relief Vehicle
                  </Button>
                </Box>
              </Stack>
            </Stack>
          </CardContent>
          </form>
          </FormProvider>
    </LocalizationProvider>
  )
}

Form.propTypes = {
  relatedContract: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fetchContractStatus: PropTypes.string,
  fleetIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  onFetchContract: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onLookup: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,

}

export default Form
