import PropTypes from 'prop-types'


export const model = [{
  key: 'id',
  label: 'ID',
  prop: {
    type: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    value: '',
  }
}, /* {
  key: 'actualReturnDate',
  label: 'Actual Return Date',
  column: {
    visible: true,
    width: 140,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, */ {
  key: 'agreementNo',
  label: 'Agreement No.',
  column: {
    visible: true,
    width: 115,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, /* {
  key: 'bookedReturnDate',
  label: 'Booked Return Date',
  column: {
    visible: true,
    width: 140,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'collectionDate',
  label: 'Collection Date',
  column: {
    visible: true,
    width: 115,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'comments',
  label: 'Comments',
  column: {
    visible: false,
    width: 200,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, */ {
  key: 'createdAt',
  label: 'Created',
  column: {
    visible: true,
    width: 100,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'driverNote',
  label: 'Driver Note',
  column: {
    visible: false,
    width: 190,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'driver',
  label: 'Driver',
  column: {
    visible: true,
    width: 180,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
},
/*
{
  key: 'driverEmail',
  label: 'Email',
  column: {
    visible: false,
    width: 160,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'driverMobile',
  label: 'Mobile',
  column: {
    visible: true,
    width: 120,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
},*/
/*  {
  key: 'extendedReturnDate',
  label: 'Extended Return Date',
  column: {
    visible: true,
    width: 170,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'location',
  label: 'Location',
  column: {
    visible: true,
    width: 120,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'referenceId',
  label: 'Reference No.',
  column: {
    visible: true,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, */ {
  key: 'registration',
  label: 'Registration',
  column: {
    visible: true,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, /* {
  key: 'rentalCompany',
  label: 'Rental Company',
  column: {
    visible: true,
    width: 140,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'reservationId',
  label: 'Reservation No.',
  column: {
    visible: true,
    width: 120,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, */ {
  key: 'status',
  label: 'Status',
  column: {
    visible: true,
    width: 170,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
},  {
  key: 'isFromApp',
  label: 'Is App Record',
  column: {
    visible: true,
    width: 140,
  },
  prop: {
    type: PropTypes.bool,
    value: false,
  }
}, {
  key: 'accountName',
  label: 'Fleet',
  column: {
    visible: true,
    width: 80,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'vehicleType',
  label: 'Vehicle Type',
  column: {
    visible: true,
    width: 140,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'collectionDate',
  label: 'Start Date',
  column: {
    visible: true,
    width: 140,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'bookedReturnDate',
  label: 'Return Date',
  column: {
    visible: true,
    width: 140,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'pickupAddress',
  label: 'Pickup Address',
  column: {
    visible: true,
    width: 240,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'comments',
  label: 'Comments',
  column: {
    visible: true,
    width: 240,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}]

const data = {
  title: 'Relief Vehicle Booking Summary',
  breadcrumbs: null,
  backgroundImage: 'url("/static/images/backgrounds/bg4.png")',
  idKey: 'id',
  api: {
    filter: '/filteroptions/vehiclerelief',
    resource: `/vehiclerelief/vehiclerelief`,
    download: `/download/vehiclerelief`,
  },
  model,
  headers: [
    'accountName', 'agreementNo', 'createdAt', 'registration',
    'assetBranch', 'assetFunction', 'driverNote', 'driver', /*'driverMobile', 'driverEmail',*/
    'status', 'isFromApp', /* 'location', 'collectionDate', 'bookedReturnDate', */
    /* 'extendedReturnDate', 'actualReturnDate', 'rentalCompany', */
    /* 'referenceId', 'reservationId' */
    'vehicleType', 'collectionDate', 'bookedReturnDate', 'pickupAddress','comments'
  ],
  filters: [
  /* {
    key: 'costCentre',
    label: 'Cost Centre',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'bookedReturnDate',
    label: 'Booked Return Date',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'collectionDate',
    label: 'Collection Date',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'make',
    label: 'Make',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'model',
    label: 'Model',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, */
  {
    key: 'status',
    label: 'Status',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'createdDate',
    label: 'Created Date',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }],
}


export default data
