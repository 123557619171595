import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Listable } from 'listable'
import { useNavigate } from 'utils'
import Row from './row'
import Filters from '../filters'
import { ChildRoutes } from '../../routes'
import { hasRoles } from 'acl'
import { useRouter } from 'utils'
import { remove, fetch } from "../../actions"

import {
  actions,
  config,
  constants
} from '../../module'
import { useEnabledFeatureToggleList } from 'features/settings'

import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"

const List = ({ model, userRoles, onRemove, onReset }) => {
  const { history } = useRouter()
  const linker = useNavigate()
  const featureToggleList = useEnabledFeatureToggleList()
  const userPermissionsFeatureToggle = featureToggleList.includes('UserPermissions')
  const vehicleRequestFeatureToggle = featureToggleList.includes('Vehicle request form and report (TFP-2162)')
  const renderItem = useCallback((item, index, itemProps) => {

    const onView = linker(constants.PATH_SINGLE, item.id)
    const onDelete = (id) => () => {
      onRemove(id);
      //onReset();
    };

    return (
      <Row
        key={index}
        item={item}
        onView={onView}
        onDelete={onDelete(item.id)}
        userRoles={userRoles}
        {...itemProps}/>
    )
  }, [linker, onRemove])

  // Prevents re-rendering if ids or data updated - no longer needed
  const { ids, filters, ...rest } = model
  if (!vehicleRequestFeatureToggle) {
    history.push("/");
    return null;
  }
  return (
    <Listable
      actions={actions}
      config={config}
      filters={Filters}
      model={rest}
      renderItem={renderItem}
      routes={ChildRoutes}
      actionButtons={
        <>
          {(userPermissionsFeatureToggle && hasRoles(['superadmin','fleetcontroller'], userRoles)) && (
            <Stack
              flexDirection={{ xs: 'column', md: 'row' }}
              justifyContent='space-between'
              gap={1}
            >
              <Stack flexDirection="row" gap={2}>
                <Box width={{ xs: '100%', md: '180px' }}>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={linker(constants.PATH_ADD)}
                  >
                    {"Request Vehicle Order"}
                  </Button>
                </Box>
              </Stack>
            </Stack>
          )}
        </>
      }
    />
  )
}

List.propTypes = {
  model: PropTypes.object.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  onRemove: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
}

const mapStateToProps = ({ requestVehicleSummary, settings }) => ({
  model: requestVehicleSummary,
  userRoles: settings.roles
})

const mapDispatchToProps = (dispatch) => ({
  onRemove: (uniqueId) => {
    dispatch(remove(null, { resourceId: uniqueId }))
  },
  onReset: () => {
    dispatch(fetch())
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List)
