import React, { useEffect, useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Alert, LookupField, RegistrationLookupWrapper } from 'frame/components'
import { createDefaultModel } from '../../module'
import { useEnabledFeatureToggleList } from 'features/settings'
import { hasRoles } from 'acl'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import DatePicker from 'frame/components/date-picker-v5'
import { useTheme } from '@mui/material/styles'
import { useForm, Controller, FormProvider } from 'react-hook-form'

import DetailsInfoWrapper from 'frame/components/details-info-wrapper'
import CardContent from 'frame/components/card-content'
import MuiTextfield from 'frame/components/mui-textfield'
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import { zodResolver } from '@hookform/resolvers/zod'
import { schema } from './schema'

const Form = ({
  relatedContract,
  disabled,
  error,
  fetchContractStatus,
  fleetIds,
  onClose,
  onFetchContract,
  onSubmit,
  onLookup,
  errorMessage,
  userRoles
}) => {
  const theme = useTheme()
  const [contract, setContract] = useState({})
  const [vehicle, setVehicle] = useState()
  const [values, setValues] = useState(createDefaultModel())
  const featureToggleList = useEnabledFeatureToggleList()
  const userPermissionsFeatureToggle = featureToggleList.includes('UserPermissions')
  const tfp1838ContactEmailFeatureToggle = featureToggleList.includes('TFP1838')
  const disableButtons = userPermissionsFeatureToggle && hasRoles(['fleetcoordinator'], userRoles)
  const lookupContractData = relatedContract?.[vehicle?.agreementNo] || null
  const locked = disabled || ! contract?.agreementNo ? true : false

  const methods = useForm({
    resolver: zodResolver(schema),
    reValidateMode: "all",
    defaultValues: {
      pickup: values?.pickup ?? "",
      destination: values?.destination ?? "",
      driverName: values?.driverName ?? "",
      driverMobile: values?.driverMobile ?? "",
      contactEmail: values?.contactEmail ?? "",
      costCentre: values?.costCentre ?? "",
    }
  })

  const hasContract = !!contract?.agreementNo

  const onRegoChange = (option) => {
    setContract({})
    setVehicle(option)
    if (option?.agreementNo) {
      onFetchContract(option.agreementNo, fleetIds)
    }
  }

  const onInputChange = ({ target: { id, name, value } }) => {
    setValues({ ...values, [id || name]: value })
    methods.setValue(name, value, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const onSubmitForm = () => {
    if (values.pickup && values.destination && values.contactEmail && values.driverMobile && values.driverName && values.costCentre) {
      onSubmit(values)
    }
  }

  // Similar to getDerivedStateFromProps
  // We need to update local state here once we receive
  // the corresponding contract.
  // So we set the contract and add appropriate contract values to the
  // relocation request as is needed.
  useEffect(() => {
    if (lookupContractData?.agreementNo) {
      setContract(lookupContractData)
      setValues({
        ...values,
        costCentre: lookupContractData.costCentre || '',
        agreementNo: lookupContractData.agreementNo || '',
        thirdPartyNumber: lookupContractData.thirdPartyNumber || '',
        vehicleRegistration: lookupContractData.registration || '',
        effectiveDate: moment(lookupContractData.maturityDate) <= moment() ? null : moment().toISOString(),
      })
      methods.setValue("costCentre", lookupContractData.costCentre);
    }
  }, [lookupContractData]) // eslint-disable-line

  const getMaxReturnDate = () => {
    if (hasContract && contract.maturityDate) {
      return moment(contract.maturityDate)
    }
    return null
  }

  const expiredMaturityDate = moment(contract.maturityDate) <= moment()

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormProvider>
          <form
            id="request-vehicle-relocation-form"
            onSubmit={methods.handleSubmit(onSubmitForm)}
          >
          <CardContent>
            {error && (
              <Stack gap={1}>
                <Alert>
                  {errorMessage || 'Something went wrong while trying to submit relief vehicle request.' }
                </Alert>
              </Stack>
            )}

            <Stack gap={4}>
              <Stack gap={2}>
                <Typography variant="h4" fontWeight={600}>
                  Vehicle
                </Typography>
                <Typography variant="bodyLarge">
                  Please select the vehicle you wish to relocate.
                </Typography>
                <Stack flex={1} gap={1}>
                  <RegistrationLookupWrapper
                    lookupContractData={contract}
                    fetchContractStatus={fetchContractStatus}>
                    <LookupField
                      id="registration"
                      disabled={disabled || disableButtons}
                      fullWidth
                      label="Vehicle Registration"
                      lookupPath="vehicleregistration"
                      value={vehicle}
                      variant="outlined"
                      validators={['required']}
                      errorMessages={['This field is required']}
                      onChange={onRegoChange}
                      onLookup={onLookup}
                      resourceModel="requestVehicleRelocations"/>
                  </RegistrationLookupWrapper>
                </Stack>
              </Stack>
              {hasContract && (
                <>
                  <Stack gap={1}>
                    <DetailsInfoWrapper
                      dataId="relief-vehicle-booking"
                      items={[{
                        label: "Driver",
                        value: contract.driver || '-',
                      },
                      {
                        label: "Location",
                        value: contract.location || '-',
                      },
                      {
                        label: "Cost Centre",
                        value: contract.costCentre || '-',
                      },
                      {
                        label: "Maturity Date",
                        value: contract.maturityDate || '-',
                        format: "Do MMMM YYYY",
                      },
                    ]}
                    />
                  </Stack>
                </>
              )}
              <Stack gap={2}>
                <Typography variant="h4" fontWeight={600}>
                  Relocation details
                </Typography>
                <Typography variant="bodyLarge">
                  Please complete all required fields.
                </Typography>
                <Stack flexDirection={{ xs: "column", md: "row" }} gap={1}>
                  <Stack width="50%">
                    <Controller
                      name="pickup"
                      control={methods.control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <MuiTextfield
                          {...field}
                          id="pickup"
                          name="pickup"
                          inputProps={{ maxLength: 50 }}
                          disabled={locked || expiredMaturityDate}
                          error={Boolean(methods.formState.errors?.pickup?.message)}
                          label="Pickup Address"
                          value={values.pickup || ''}
                          onChange={onInputChange}
                        />
                      )}
                    />
                    <Typography color="error" fontSize={12}>
                      {methods.formState.errors?.pickup?.message}
                    </Typography>
                  </Stack>
                  <Stack width="50%">
                    <Controller
                      name="destination"
                      control={methods.control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <MuiTextfield
                          {...field}
                          id="destination"
                          name="destination"
                          inputProps={{ maxLength: 50 }}
                          disabled={locked || expiredMaturityDate}
                          error={Boolean(methods.formState.errors?.destination?.message)}
                          label="Destination Address"
                          value={values.destination || ''}
                          onChange={onInputChange}
                        />
                      )}
                    />
                    <Typography color="error" fontSize={12}>
                      {methods.formState.errors?.destination?.message}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack flexDirection={{ xs: "column", md: "row" }} gap={1}>
                  <Stack width="50%">
                    <Controller
                      name="driverName"
                      control={methods.control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <MuiTextfield
                          {...field}
                          id="driverName"
                          name="driverName"
                          inputProps={{ maxLength: 50 }}
                          disabled={locked || expiredMaturityDate}
                          error={Boolean(methods.formState.errors?.driverName?.message)}
                          label="Contact Person"
                          value={values.driverName || ''}
                          onChange={onInputChange}
                        />
                      )}
                    />
                    <Typography color="error" fontSize={12}>
                      {methods.formState.errors?.driverName?.message}
                    </Typography>
                  </Stack>
                  <Stack width="50%">
                    <Controller
                      name="driverMobile"
                      control={methods.control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <MuiTextfield
                          {...field}
                          id="driverMobile"
                          name="driverMobile"
                          disabled={locked || expiredMaturityDate}
                          error={Boolean(methods.formState.errors?.driverMobile?.message)}
                          label="Mobile"
                          value={values.driverMobile || ''}
                          onChange={onInputChange}
                        />
                      )}
                    />
                    <Typography color="error" fontSize={12}>
                      {methods.formState.errors?.driverMobile?.message}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack flexDirection={{ xs: "column", md: "row" }} gap={1}>
                  {tfp1838ContactEmailFeatureToggle &&
                    <Stack width="50%">
                      <Controller
                        name="contactEmail"
                        control={methods.control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <MuiTextfield
                            {...field}
                            id="contactEmail"
                            name="contactEmail"
                            inputProps={{ maxLength: 50 }}
                            disabled={locked || expiredMaturityDate}
                            error={Boolean(methods.formState.errors?.contactEmail?.message)}
                            label="Contact Email"
                            value={values.contactEmail || ''}
                            onChange={onInputChange}
                          />
                        )}
                      />
                      <Typography color="error" fontSize={12}>
                        {methods.formState.errors?.contactEmail?.message}
                      </Typography>
                    </Stack>
                  }
                  <Stack width="50%">
                    <Controller
                      name="costCentre"
                      control={methods.control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <MuiTextfield
                          {...field}
                          id="costCentre"
                          name="costCentre"
                          inputProps={{ maxLength: 50 }}
                          disabled={locked || expiredMaturityDate}
                          error={Boolean(methods.formState.errors?.costCentre?.message)}
                          label="Cost Centre"
                          value={values.costCentre || ''}
                          onChange={onInputChange}
                        />
                      )}
                    />
                    <Typography color="error" fontSize={12}>
                      {methods.formState.errors?.costCentre?.message}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack flexDirection={{ xs: "column", md: "row" }} gap={1}>
                  <Stack width="50%">
                    <DatePicker
                      label="Effective Date"
                      format="DD/MM/YYYY"
                      minDate={dayjs()}
                      maxDate={getMaxReturnDate()}
                      value={values?.effectiveDate ? dayjs(values?.effectiveDate) : null}
                      disabled={locked || expiredMaturityDate}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          effectiveDate: value,
                        })
                        methods.setValue("effectiveDate", dayjs(value).toDate(), {
                          shouldValidate: true,
                          shouldDirty: true,
                        })
                      }}
                    />
                    <Typography color="error" fontSize={12}>
                      {methods.formState.errors?.effectiveDate?.message}
                    </Typography>
                  </Stack>
                  <Stack width="50%">
                  </Stack>
                </Stack>
              </Stack>
            </Stack>

            <Divider sx={{ mt: 3, mb: 2, borderColor: theme.palette.accent.sky }} />
            <Stack
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent="space-between"
              rowGap={2}
            >
              <Box width={{ xs: "100%", md: "205px" }}>
                <Button
                  fullWidth
                  color="error"
                  variant="outlined"
                  onClick={onClose}
                >
                  Close
                </Button>
              </Box>
              <Stack flexDirection="row" gap={1}>
                
                <Box width={{ xs: "100%", md: "205px" }}>
                  <Button
                    fullWidth
                    color="error"
                    variant="outlined"
                    disabled={locked}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </Box>
                <Box width={{ xs: "100%", md: "215px" }}>
                  <Button
                    type="submit"
                    fullWidth
                    color="primary"
                    disabled={locked || expiredMaturityDate}
                    variant="contained"
                  >
                    Request Vehicle Relocation
                  </Button>
                </Box>
              </Stack>
            </Stack>
          </CardContent>
          </form>
          </FormProvider>
    </LocalizationProvider>
  )
}

Form.propTypes = {
  contracts: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fetchContractStatus: PropTypes.string,
  fleetIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  onFetchContract: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onLookup: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default Form
